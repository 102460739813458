<template>
  <div class="item__impact">
    <span>
      <Instrument width="24" height="24" class="instrument" :id="impact[0].symbol.toLowerCase()"/>
      {{ impact[0].name }}
    </span>

    <ItemImpactTip>
      {{ $t('impact.on') }} {{ impact[0].name }}<br><br>
      <a class="item__impact-link" target="_blank" data-icid="button"
        :data-icid-id="`btn_wdg_eccal_goto_${code.toLowerCase()}`"
        :href="`https://${$store.state.app}${impact[0].deeplink}`"
        >{{ $t('impact.goto_instrument', {instrument: ''}) }} <icon-arrow fill="#1192E8" width="14" height="10" /></a><br><br>
      <span v-html="substituteTip"></span>
    </ItemImpactTip>
  </div>
</template>

<script>
import ItemImpactTip from '@/components/ItemImpactTip'
import { Instrument, IconArrow } from '@/ui'

export default {
  name: 'ItemImpact',

  components: {
    ItemImpactTip,
    Instrument,
    IconArrow
  },

  props: {
    impact: {
      type: Array,
      required: true
    }
  },

  computed: {
    pips () {
      return this.impact[0].value
    },
    code () {
      return this.impact[0].name.replace(/\//g, '')
    },
    substituteTip () {
      const pipsStr = `<strong>${this.$t('impact.pips', { pips: this.pips })}</strong>`

      return this.$t('impact.measured', { pips: pipsStr })
    }
  }
}
</script>

<style scoped>
.item__impact {
  position: relative;
  line-height: 48px;
}

.instrument {
  margin-right: 8px;
  vertical-align: middle;
}

.item__impact:hover .item__impact-tip {
  display: block;
}

.item__impact-tip::before {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 20px;
  display: block;
  width: 0;
  height: 0;
  border-width: 8px 8px 0 8px;
  border-color: #fff transparent;
  border-style: solid;
}

.item__impact-link {
  color: #1192E8;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
}
</style>
