const debounce = function (func, wait) {
  let timeout

  return function () {
    const context = this
    const args = arguments

    clearTimeout(timeout)

    timeout = setTimeout(function () {
      func.apply(context, args)
    }, wait)
  }
}

const throttle = function (func, ms) {
  let isThrottled = false
  let savedArgs
  let savedThis

  function wrapper () {
    if (isThrottled) {
      savedArgs = arguments
      savedThis = this

      return
    }

    func.apply(this, arguments)
    isThrottled = true

    setTimeout(function () {
      isThrottled = false

      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs)
        savedArgs = savedThis = null
      }
    }, ms)
  }

  return wrapper
}

const checkDate = function (date) {
  const parsedDate = Date.parse(date)

  return isNaN(date) && !isNaN(parsedDate)
}

const sortInDays = function (all) {
  const sorted = []
  let i = 0

  while (all[i] !== undefined) {
    const day = []

    do {
      day.push(all[i])
      i++
    } while (all[i] !== undefined && all[i].timestamp.slice(0, 10) === all[i - 1].timestamp.slice(0, 10))

    sorted.push(day)
  }

  return sorted
}

const copyEventAddress = function (id, element) {
  copyUrl(id)
  notify(element)
}

const copyUrl = function (link) {
  const input = document.createElement('input')

  input.type = 'text'
  input.style.position = 'fixed'
  input.style.top = '0'
  input.style.left = '0'
  input.style.transform = 'translate(-100%, -100%)'
  input.value = window.location.origin + window.location.pathname + link

  document.body.appendChild(input)
  input.select()
  document.execCommand('copy')
  input.remove()
}

const notify = function (element) {
  element.classList.add('btn-action--active')

  setTimeout(function () {
    element.classList.remove('btn-action--active')
  }, 500)
}

const download = function (file, name) {
  const link = document.createElement('a')

  link.setAttribute('href', file)
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const blockScrolling = function (need2block) {
  document.body.style.overflow = need2block ? 'hidden' : ''
}

const ranges = [
  // { divider: 1e18 , suffix: 'E' },
  // { divider: 1e15 , suffix: 'P' },
  // { divider: 1e12 , suffix: 'T' },
  { divider: 1e9, suffix: 'B' },
  { divider: 1e6, suffix: 'M' },
  { divider: 1e3, suffix: 'k' }
]

const shrinkDecimals = function (n) {
  return Math.round((n + Number.EPSILON) * 1000) / 1000 // to ensure things like 1.005 round correctly
}

const formatNumber = function (n) {
  if (n === null) return ''

  n = parseFloat(n)

  if (n < 0) return '−' + formatNumber(-n)

  for (var i = 0; i < ranges.length; i++) {
    if (n >= ranges[i].divider) {
      return shrinkDecimals(n / ranges[i].divider).toString() + ranges[i].suffix
    }
  }

  return shrinkDecimals(n).toString()
}

const getFactColor = function (forecast, fact) {
  if (forecast === null || fact === null) return ''
  if (forecast === fact) return ''

  return Number(forecast) < Number(fact) ? 'cell__fact--green' : 'cell__fact--red'
}

const safeParseDate = function (date) { // IE11 hack
  if (date.split(':').length === 3) return date.slice(0, 22) + ':' + date.slice(22)
  else return date
}

const decimalAdjust = function (type, value, exp) {
  // If exp is not defined or equals zero
  if (typeof exp === 'undefined' || +exp === 0) return Math[type](value)

  value = +value
  exp = +exp

  // If value is not a number, or exp is not integer
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) return NaN

  // Shift digits
  value = value.toString().split('e')
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)))
  // Reverse shift
  value = value.toString().split('e')

  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp))
}

const decimalCeil = function (value, exp) {
  return decimalAdjust('ceil', value, exp)
}

const generateScale = function (min, max) {
  const scale = []
  const diff = max - min
  const exp = diff.toExponential(3)
  const index = parseInt(exp) !== 1 ? exp.split('e')[1] : exp.split('e')[1] - 1
  const step = Number('1e' + index)

  for (let point = decimalCeil(min, index); point <= max; point = point + step) {
    scale.push(point)
  }

  return scale
}

export default {
  debounce,
  throttle,
  checkDate,
  sortInDays,
  copyEventAddress,
  download,
  blockScrolling,
  formatNumber,
  getFactColor,
  safeParseDate,
  generateScale
}
