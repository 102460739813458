import axios from 'axios'

const getAll = (src, params) => { // params = { from, to, timezone, lang, region, importance }
  const url = src + '/api/events/'
  let query = ''

  Object.keys(params).forEach((key, i) => {
    if (params[key]) {
      query += (query === '') ? '?' : '&'
      query += `${key}=${encodeURIComponent(params[key])}`
    }
  })

  return axios
    .get(`${url}${query}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.warn(error)
      return []
    })
}

const getOne = ({ src, id, lang }) => {
  const url = `${src}/api/events/${id}/?lang=${lang}`

  return axios
    .get(url)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.warn(error)
      return {}
    })
}

const getEventData = ({ src, id, lang }) => {
  const url = `${src}/api/events/${id}/history/?lang=${lang}`

  return axios
    .get(url)
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.warn(error)
      return []
    })
}

export default {
  getAll,
  getOne,
  getEventData
}

// const getSeminars1 = ({ branch }) => Promise.resolve([
//   {
//       "title": "Westpac Leading Index MoM",
//       "description": "The Westpac-Melbourne Institute Leading Index of Economic Activity combines a selection of economic variables that typically lead fluctuations in economic activity into a single measure that provides a reliable cyclical indicator for the Australian economy. The index includes the following components: S&P/ASX 200, dwelling approvals, US industrial production, RBA Commodity Prices Index (A$), aggregate monthly hours worked, Westpac-MI CSI expectations index, Westpac-MI Unemployment expectations index, yield spread (10Y bond – 90D bill). The index has a base value of 100 as of 1996.",
//       "type": "AU:Westpac Leading Index MoM",
//       "region": "AU",
//       "impacts": [],
//       "actual": "0.40",
//       "previous": "0.20",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T00:30:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "Jibun Bank Manufacturing PMI Flash",
//       "description": "The Jibun Bank Japan Manufacturing PMI® is compiled by IHS Markit from responses to monthly questionnaires sent to purchasing managers in a panel of around 400 manufacturers. The panel is stratified by detailed sector and company workforce size, based on contributions to GDP. Survey responses indicate the direction of change compared to the previous month. A diffusion index is calculated for each survey variable. The index is the sum of the percentage of ‘higher’ responses and half the percentage of ‘unchanged’ responses. The indices vary between 0 and 100, with a reading above 50 indicating an overall increase compared to the previous month, and below 50 an overall decrease. The PMI is a weighted average of the following five indices: New Orders (30%), Output (25%), Employment (20%), Suppliers’ Delivery Times (15%) and Stocks of Purchases (10%). For the PMI calculation the Suppliers’ Delivery Times Index is inverted so that it moves in a comparable direction to the other indices.",
//       "type": "JP:Jibun Bank Manufacturing PMI Flash",
//       "region": "JP",
//       "impacts": [
//           {
//               "name": "USD/JPY",
//               "value": "21.958333",
//               "deeplink": "https://libertex.fxclub.org/deeplink.html?mobile=new-investment%2FUSDJPY&tablet=products%2Fcurrency%2FUSDJPY%2Fnew-investment&desktop=%23modal_newInvest_USDJPY"
//           }
//       ],
//       "actual": "42.60",
//       "previous": "40.10",
//       "forecast": null,
//       "importance": "medium",
//       "timestamp": "2020-07-22T00:30:00",
//       "unit": "",
//       "currency": ""
//   },
//   {
//       "title": "Jibun Bank Services PMI Flash",
//       "description": "The Japan Services PMI (Purchasing Managers' Index) is based on data collected from a representative panel of over 400 private service sector companies based in Japan. The index tracks variables such as sales, employment, inventories and prices. A reading above 50 indicates that the services sector is generally expanding; below 50 indicates that it is generally declining.",
//       "type": "JP:Jibun Bank Services PMI Flash",
//       "region": "JP",
//       "impacts": [],
//       "actual": "45.20",
//       "previous": "45.00",
//       "forecast": null,
//       "importance": "medium",
//       "timestamp": "2020-07-22T00:30:00",
//       "unit": "",
//       "currency": ""
//   },
//   {
//       "title": "Jibun Bank Composite PMI Flash",
//       "description": "In Japan, the Nikkei Japan Composite Output Index tracks business trends across private sector activity, based on data collected from a representative panel of around 800 companies. The index tracks variables such as sales, new orders, employment, inventories and prices. A reading above 50 indicates expansion in business activity and below 50 indicates that it is generally declining.",
//       "type": "JP:Jibun Bank Composite PMI Flash",
//       "region": "JP",
//       "impacts": [],
//       "actual": "43.90",
//       "previous": "40.80",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T00:30:00",
//       "unit": "",
//       "currency": ""
//   },
//   {
//       "title": "Consumer Confidence",
//       "description": "In the Netherlands, the Consumer Confidence survey is made by phone and covers 1,000 households. The questions cover the consumer’s assessments on national economy and own financial situation for the past 12 months and expectations for the coming 12 months. The index is then calculated as a difference between the percentage of participants that are optimistic and the share that is pessimistic. Therefore, the index takes a value between -100 (all respondents assess their situation as poor and expect it to become worse) and 100 (all participants are satisfied with the current situation and expect it to improve); 0 indicates neutrality.",
//       "type": "NL:Consumer Confidence",
//       "region": "NL",
//       "impacts": [],
//       "actual": null,
//       "previous": "-27.00",
//       "forecast": null,
//       "importance": "medium",
//       "timestamp": "2020-07-22T04:30:00",
//       "unit": "",
//       "currency": ""
//   },
//   {
//       "title": "Household Consumption YoY",
//       "description": "In the Netherlands, Household Consumption YoY measures the year-on-year change of consumption expenditure by households. Consumption expenditure includes all voluntary expenditure on goods and services by private individuals and households. It does not include compulsory expenditure such as taxes. Services is the most important sector and accounts for 58 percent of total consumption. The biggest segments within Services are housing; financial and business services; transport, communication services; and medical services and welfare. Consumption of goods account for the remaining 42 percent, in particular durable consumer goods (15 percent) and food, beverages and tobacco (15 percent).",
//       "type": "NL:Household Consumption YoY",
//       "region": "NL",
//       "impacts": [
//           {
//               "name": "EUR/USD",
//               "value": "26.658333",
//               "deeplink": "https://libertex.fxclub.org/deeplink.html?mobile=new-investment%2FEURUSD&tablet=products%2Fcurrency%2FEURUSD%2Fnew-investment&desktop=%23modal_newInvest_EURUSD"
//           }
//       ],
//       "actual": null,
//       "previous": "-17.40",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T04:30:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "Foreign Direct Investment YoY",
//       "description": "In Indonesia, Foreign Direct Investment refers to the flows of the foreign direct investments received from abroad. The FDI data does not include investment in banking and oil and gas sectors.",
//       "type": "ID:Foreign Direct Investment YoY",
//       "region": "ID",
//       "impacts": [],
//       "actual": "-6.90",
//       "previous": "-9.20",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T05:00:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "Motorbike Sales YoY",
//       "description": "",
//       "type": "ID:Motorbike Sales YoY",
//       "region": "ID",
//       "impacts": [],
//       "actual": null,
//       "previous": "-96.10",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T05:30:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "SACCI Business Confidence",
//       "description": "In South Africa, the SACCI Business Confidence Index reflects the neutral sentiment in the market place, as it captures the prevailing business climate and what businesses are experiencing. The index is composed by thirteen sub-indices, including energy supply, manufacturing, exports, imports, vehicle and retail sales, construction, inflation, share prices, private sector borrowing, financing cost, precious metal prices and exchange rate.",
//       "type": "ZA:SACCI Business Confidence",
//       "region": "ZA",
//       "impacts": [],
//       "actual": null,
//       "previous": "77.80",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T09:30:00",
//       "unit": "",
//       "currency": ""
//   },
//   {
//       "title": "SACCI Business Confidence",
//       "description": "In South Africa, the SACCI Business Confidence Index reflects the neutral sentiment in the market place, as it captures the prevailing business climate and what businesses are experiencing. The index is composed by thirteen sub-indices, including energy supply, manufacturing, exports, imports, vehicle and retail sales, construction, inflation, share prices, private sector borrowing, financing cost, precious metal prices and exchange rate.",
//       "type": "ZA:SACCI Business Confidence",
//       "region": "ZA",
//       "impacts": [],
//       "actual": null,
//       "previous": null,
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T09:30:00",
//       "unit": "",
//       "currency": ""
//   },
//   {
//       "title": "30-Year Bund Auction",
//       "description": "",
//       "type": "DE:30-Year Bund Auction",
//       "region": "DE",
//       "impacts": [
//           {
//               "name": "EUR/USD",
//               "value": "40.000000",
//               "deeplink": "https://libertex.fxclub.org/deeplink.html?mobile=new-investment%2FEURUSD&tablet=products%2Fcurrency%2FEURUSD%2Fnew-investment&desktop=%23modal_newInvest_EURUSD"
//           }
//       ],
//       "actual": null,
//       "previous": "-0.13",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T09:40:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "Wholesale Prices MoM",
//       "description": "In Ireland, the Producer Price Index measures the average change in price of goods and services sold by manufacturers and producers in the wholesale market during a given period.",
//       "type": "IE:Wholesale Prices MoM",
//       "region": "IE",
//       "impacts": [
//           {
//               "name": "EUR/USD",
//               "value": "35.616667",
//               "deeplink": "https://libertex.fxclub.org/deeplink.html?mobile=new-investment%2FEURUSD&tablet=products%2Fcurrency%2FEURUSD%2Fnew-investment&desktop=%23modal_newInvest_EURUSD"
//           }
//       ],
//       "actual": null,
//       "previous": "-1.00",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T10:00:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "Wholesale Prices YoY",
//       "description": "Producer prices change refers to year over year change in price of goods and services sold by manufacturers and producers in the wholesale market during a given period.",
//       "type": "IE:Wholesale Prices YoY",
//       "region": "IE",
//       "impacts": [],
//       "actual": null,
//       "previous": "-7.40",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T10:00:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "Retail Sales YoY",
//       "description": "In Mexico, the year-over-year change in Retail sales compares the aggregated sales of retail goods and services during a certain month to the same month a year ago.",
//       "type": "MX:Retail Sales YoY",
//       "region": "MX",
//       "impacts": [],
//       "actual": null,
//       "previous": "-23.80",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T11:00:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "Retail Sales MoM",
//       "description": "In Mexico, the Retail sales report provides an aggregated measure of sales of retail goods and services over a specific time period. In Mexico, Retail sales are seasonal, volatile and relatively important to the overall economy.",
//       "type": "MX:Retail Sales MoM",
//       "region": "MX",
//       "impacts": [],
//       "actual": null,
//       "previous": "-22.40",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T11:00:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "MBA 30-Year Mortgage Rate",
//       "description": "MBA 30-Year Mortgage Rate is average 30-year fixed mortgage lending rate measured during the reported week and backed by the Mortgage Bankers Association.",
//       "type": "US:MBA 30-Year Mortgage Rate",
//       "region": "US",
//       "impacts": [
//           {
//               "name": "EUR/USD",
//               "value": "51.241667",
//               "deeplink": "https://libertex.fxclub.org/deeplink.html?mobile=new-investment%2FEURUSD&tablet=products%2Fcurrency%2FEURUSD%2Fnew-investment&desktop=%23modal_newInvest_EURUSD"
//           }
//       ],
//       "actual": null,
//       "previous": "3.19",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T11:00:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "MBA Mortgage Applications",
//       "description": "In the US, the MBA Weekly Mortgage Application Survey is a comprehensive overview of the nationwide mortgage market and covers all types of mortgage originators, including commercial banks, thrift institutions and mortgage banking companies. The entire market is represented by the Market Index which covers all mortgage applications during the week, whether for a purchase or to refinance.",
//       "type": "US:MBA Mortgage Applications",
//       "region": "US",
//       "impacts": [],
//       "actual": null,
//       "previous": "5.10",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T11:00:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "Retail Sales YoY",
//       "description": "In South Africa, the year-over-year change in Retail sales compares the aggregated sales of retail goods and services during a certain month to the same month a year ago.",
//       "type": "ZA:Retail Sales YoY",
//       "region": "ZA",
//       "impacts": [],
//       "actual": null,
//       "previous": "2.70",
//       "forecast": null,
//       "importance": "medium",
//       "timestamp": "2020-07-22T11:00:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "Retail Sales YoY",
//       "description": "In South Africa, the year-over-year change in Retail sales compares the aggregated sales of retail goods and services during a certain month to the same month a year ago.",
//       "type": "ZA:Retail Sales YoY",
//       "region": "ZA",
//       "impacts": [],
//       "actual": null,
//       "previous": null,
//       "forecast": null,
//       "importance": "medium",
//       "timestamp": "2020-07-22T11:00:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "Retail Sales MoM",
//       "description": "In South Africa, the Retail sales report provides an aggregated measure of sales of retail goods and services over a specific time period. In South Africa, Retail sales are seasonal, volatile and relatively important to the overall economy.",
//       "type": "ZA:Retail Sales MoM",
//       "region": "ZA",
//       "impacts": [],
//       "actual": null,
//       "previous": "2.30",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T11:00:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "Retail Sales MoM",
//       "description": "In South Africa, the Retail sales report provides an aggregated measure of sales of retail goods and services over a specific time period. In South Africa, Retail sales are seasonal, volatile and relatively important to the overall economy.",
//       "type": "ZA:Retail Sales MoM",
//       "region": "ZA",
//       "impacts": [],
//       "actual": null,
//       "previous": null,
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T11:00:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "M3 Money Supply YoY",
//       "description": "Poland Money Supply M3 includes M2 plus long-term time deposits in banks.",
//       "type": "PL:M3 Money Supply YoY",
//       "region": "PL",
//       "impacts": [],
//       "actual": null,
//       "previous": "16.00",
//       "forecast": "17.50",
//       "importance": "low",
//       "timestamp": "2020-07-22T12:00:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "Inflation Rate YoY",
//       "description": "In Canada, the most important categories in the CPI basket are Shelter (27.5 percent of the total weight) and Transportation (19.3 percent).  Food accounts for 16.1 percent; Household Operations, Furnishings and Equipment for 11.8 percent; Recreation, Education and Reading for 11.8 percent; Clothing and Footwear for 5.7 percent; Health and Personal Care for 5 percent; Alcoholic Beverages and Tobacco Products for the remaining 3 percent. The CPI basket is reviewed every four years on the basis of household surveys. The current weights are based on spending patterns in 2009.",
//       "type": "CA:Inflation Rate YoY",
//       "region": "CA",
//       "impacts": [],
//       "actual": null,
//       "previous": "-0.40",
//       "forecast": "0.30",
//       "importance": "high",
//       "timestamp": "2020-07-22T12:30:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "Inflation Rate MoM",
//       "description": "Inflation Rate MoM measures month over month change in the price of goods and services.",
//       "type": "CA:Inflation Rate MoM",
//       "region": "CA",
//       "impacts": [],
//       "actual": null,
//       "previous": "0.30",
//       "forecast": "0.40",
//       "importance": "medium",
//       "timestamp": "2020-07-22T12:30:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "Core Inflation Rate YoY",
//       "description": "In Canada, the core inflation rate tracks changes in prices that consumers pay for a basket of goods which excludes some volatile price items.",
//       "type": "CA:Core Inflation Rate YoY",
//       "region": "CA",
//       "impacts": [],
//       "actual": null,
//       "previous": "0.70",
//       "forecast": "0.90",
//       "importance": "medium",
//       "timestamp": "2020-07-22T12:30:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "Bundesbank Buch Speech",
//       "description": "Germany is a member of the European Union which has adopted the euro. Germany's benchmark interest rate is set by the European Central Bank. The official designation for the rate is main refinancing operation.",
//       "type": "DE:Bundesbank Buch Speech",
//       "region": "DE",
//       "impacts": [
//           {
//               "name": "EUR/USD",
//               "value": "41.500000",
//               "deeplink": "https://libertex.fxclub.org/deeplink.html?mobile=new-investment%2FEURUSD&tablet=products%2Fcurrency%2FEURUSD%2Fnew-investment&desktop=%23modal_newInvest_EURUSD"
//           }
//       ],
//       "actual": null,
//       "previous": null,
//       "forecast": null,
//       "importance": "medium",
//       "timestamp": "2020-07-22T13:00:00",
//       "unit": "",
//       "currency": ""
//   },
//   {
//       "title": "House Price Index MoM",
//       "description": "The FHFA (Federal Housing Finance Agency) House Price Index measures month over month changes in average prices of single-family houses with mortgages guaranteed by Fannie Mae and Freddie Mac.",
//       "type": "US:House Price Index MoM",
//       "region": "US",
//       "impacts": [
//           {
//               "name": "EUR/USD",
//               "value": "31.875000",
//               "deeplink": "https://libertex.fxclub.org/deeplink.html?mobile=new-investment%2FEURUSD&tablet=products%2Fcurrency%2FEURUSD%2Fnew-investment&desktop=%23modal_newInvest_EURUSD"
//           }
//       ],
//       "actual": null,
//       "previous": "0.20",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T13:00:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "Federal Tax Revenues",
//       "description": "Government Revenues refer to all receipts the government gets, including taxes, custom duties, revenue from state-owned enterprises, capital revenues and foreign aid. Government Revenues are part of government budget balance calculation.",
//       "type": "BR:Federal Tax Revenues",
//       "region": "BR",
//       "impacts": [],
//       "actual": null,
//       "previous": "77400000000.00",
//       "forecast": "88348000000.00",
//       "importance": "low",
//       "timestamp": "2020-07-22T13:30:00",
//       "unit": "",
//       "currency": "BRL"
//   },
//   {
//       "title": "Existing Home Sales MoM",
//       "description": "Existing Home Sales occurs when the mortgage is closed. Mortgage closing usually takes place 30-60 days after the sales contract is closed. \n\n\n.",
//       "type": "US:Existing Home Sales MoM",
//       "region": "US",
//       "impacts": [
//           {
//               "name": "EUR/USD",
//               "value": "32.175000",
//               "deeplink": "https://libertex.fxclub.org/deeplink.html?mobile=new-investment%2FEURUSD&tablet=products%2Fcurrency%2FEURUSD%2Fnew-investment&desktop=%23modal_newInvest_EURUSD"
//           }
//       ],
//       "actual": null,
//       "previous": "-9.70",
//       "forecast": "24.50",
//       "importance": "medium",
//       "timestamp": "2020-07-22T14:00:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "Existing Home Sales",
//       "description": "Existing Home Sales occurs when the mortgage is closed. Mortgage closing usually takes place 30-60 days after the sales contract is closed. \n\n\n.",
//       "type": "US:Existing Home Sales",
//       "region": "US",
//       "impacts": [],
//       "actual": null,
//       "previous": "3910000.00",
//       "forecast": "4780000.00",
//       "importance": "medium",
//       "timestamp": "2020-07-22T14:00:00",
//       "unit": "",
//       "currency": ""
//   },
//   {
//       "title": "EIA Gasoline Stocks Change",
//       "description": "Stocks of gasoline refers to the weekly change of the gasoline supply situation.",
//       "type": "US:EIA Gasoline Stocks Change",
//       "region": "US",
//       "impacts": [
//           {
//               "name": "EUR/USD",
//               "value": "37.291667",
//               "deeplink": "https://libertex.fxclub.org/deeplink.html?mobile=new-investment%2FEURUSD&tablet=products%2Fcurrency%2FEURUSD%2Fnew-investment&desktop=%23modal_newInvest_EURUSD"
//           }
//       ],
//       "actual": null,
//       "previous": "-3147000.00",
//       "forecast": "-1175000.00",
//       "importance": "medium",
//       "timestamp": "2020-07-22T14:30:00",
//       "unit": "",
//       "currency": ""
//   },
//   {
//       "title": "EIA Crude Oil Stocks Change",
//       "description": "Stocks of crude oil refer to the weekly change of the crude oil supply situation.",
//       "type": "US:EIA Crude Oil Stocks Change",
//       "region": "US",
//       "impacts": [],
//       "actual": null,
//       "previous": "-7493000.00",
//       "forecast": "-1950000.00",
//       "importance": "medium",
//       "timestamp": "2020-07-22T14:30:00",
//       "unit": "",
//       "currency": ""
//   },
//   {
//       "title": "EIA Distillate Stocks Change",
//       "description": "",
//       "type": "US:EIA Distillate Stocks Change",
//       "region": "US",
//       "impacts": [],
//       "actual": null,
//       "previous": "-453000.00",
//       "forecast": "-550000.00",
//       "importance": "medium",
//       "timestamp": "2020-07-22T14:30:00",
//       "unit": "",
//       "currency": ""
//   },
//   {
//       "title": "EIA Cushing Crude Oil Stocks Change",
//       "description": "Change in the number of barrels of crude oil held in storage at the Cushing, Oklahoma during the past week.",
//       "type": "US:EIA Cushing Crude Oil Stocks Change",
//       "region": "US",
//       "impacts": [],
//       "actual": null,
//       "previous": "949000.00",
//       "forecast": null,
//       "importance": "medium",
//       "timestamp": "2020-07-22T14:30:00",
//       "unit": "",
//       "currency": ""
//   },
//   {
//       "title": "EIA Distillate Fuel Production Change",
//       "description": "",
//       "type": "US:EIA Distillate Fuel Production Change",
//       "region": "US",
//       "impacts": [],
//       "actual": null,
//       "previous": "104000.00",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T14:30:00",
//       "unit": "",
//       "currency": ""
//   },
//   {
//       "title": "EIA Gasoline Production Change",
//       "description": "",
//       "type": "US:EIA Gasoline Production Change",
//       "region": "US",
//       "impacts": [],
//       "actual": null,
//       "previous": "50000.00",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T14:30:00",
//       "unit": "",
//       "currency": ""
//   },
//   {
//       "title": "EIA Crude Oil Imports Change",
//       "description": "",
//       "type": "US:EIA Crude Oil Imports Change",
//       "region": "US",
//       "impacts": [],
//       "actual": null,
//       "previous": "-1983000.00",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T14:30:00",
//       "unit": "",
//       "currency": ""
//   },
//   {
//       "title": "EIA Refinery Crude Runs Change",
//       "description": "Crude Runs refer to the volume of crude oil consumed by refineries.",
//       "type": "US:EIA Refinery Crude Runs Change",
//       "region": "US",
//       "impacts": [],
//       "actual": null,
//       "previous": "-38000.00",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T14:30:00",
//       "unit": "",
//       "currency": ""
//   },
//   {
//       "title": "ECB Guindos Speech",
//       "description": "In the Euro Area, benchmark interest rate is set by the Governing Council of the European Central Bank. The primary objective of the ECB’s monetary policy is to maintain price stability which is to keep inflation below, but close to 2 percent over the medium term. In times of prolonged low inflation and low interest rates, ECB may also adopt non-standard monetary policy measures, such as asset purchase programmes. The official interest rate is the Main refinancing operations rate.\n.",
//       "type": "EA:ECB Guindos Speech",
//       "region": "EA",
//       "impacts": [
//           {
//               "name": "EUR/USD",
//               "value": "42.241667",
//               "deeplink": "https://libertex.fxclub.org/deeplink.html?mobile=new-investment%2FEURUSD&tablet=products%2Fcurrency%2FEURUSD%2Fnew-investment&desktop=%23modal_newInvest_EURUSD"
//           }
//       ],
//       "actual": null,
//       "previous": null,
//       "forecast": null,
//       "importance": "medium",
//       "timestamp": "2020-07-22T15:00:00",
//       "unit": "",
//       "currency": ""
//   },
//   {
//       "title": "20-Year Bond Auction",
//       "description": "",
//       "type": "US:20-Year Bond Auction",
//       "region": "US",
//       "impacts": [
//           {
//               "name": "EUR/USD",
//               "value": "17.950000",
//               "deeplink": "https://libertex.fxclub.org/deeplink.html?mobile=new-investment%2FEURUSD&tablet=products%2Fcurrency%2FEURUSD%2Fnew-investment&desktop=%23modal_newInvest_EURUSD"
//           }
//       ],
//       "actual": null,
//       "previous": "1.31",
//       "forecast": null,
//       "importance": "low",
//       "timestamp": "2020-07-22T17:00:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "GDP Growth Rate YoY Adv",
//       "description": "South Korea’s economy became one of the most diversified and technologically advanced in the world in the last 50 years. Like in most developed countries, services account for the highest percentage of GDP (around 57 percent of GDP). Within services the most important ones are public administration (6 percent ); education (5.3 percent); information and communication (5 percent); and business activities (4.6 percent of GDP). A strong manufacturing industry which has turned South Korea into a major exporter accounts for 32 percent of GDP.",
//       "type": "KR:GDP Growth Rate YoY Adv",
//       "region": "KR",
//       "impacts": [],
//       "actual": null,
//       "previous": "1.40",
//       "forecast": "-2.00",
//       "importance": "medium",
//       "timestamp": "2020-07-22T23:00:00",
//       "unit": "%",
//       "currency": ""
//   },
//   {
//       "title": "GDP Growth Rate QoQ Adv",
//       "description": "South Korea has an export oriented economy and is the world's leading producer of displays and memory semiconductors and the second largest producer of ships. Services account for the highest percentage of GDP (around 57 percent of GDP). Within services the most important ones are public administration (6 percent ); education (5.3 percent); information and communication (5 percent); and business activities (4.6 percent of GDP). A strong manufacturing industry which has turned South Korea into a major exporter accounts for 32 percent of GDP.",
//       "type": "KR:GDP Growth Rate QoQ Adv",
//       "region": "KR",
//       "impacts": [],
//       "actual": null,
//       "previous": "-1.30",
//       "forecast": "-2.30",
//       "importance": "medium",
//       "timestamp": "2020-07-22T23:00:00",
//       "unit": "%",
//       "currency": ""
//   }
// ])
