<template>
  <div>
    <component :is="EventSize" :event="event" />
  </div>
</template>

<script>
import Event from '@/components/Event.vue'
import EventMobile from '@/components/mobile/Event.vue'
import api from '@/api'

export default {
  name: 'Single',

  components: {
    Event,
    EventMobile
  },

  computed: {
    id () {
      return this.$route.params.id
    },
    EventSize () {
      return this.$store.state.widgetWidth < 900
        ? 'EventMobile'
        : 'Event'
    }
  },

  data () {
    return {
      event: {}
    }
  },

  created () {
    this.$store.commit('setLoadState', { isLoading: true })

    api.getOne({
      id: this.id,
      lang: this.$store.state.language,
      src: this.$config.default.src
    })
      .then(data => {
        this.event = data
        this.$store.commit('setLoadState', { isLoading: false })
      })
  }
}
</script>
