<template>
  <div class="datespickers">
    <div class="datespickers__inputs">
      <label class="datespickers__label">
        <div>{{ $t('filters.from') }}</div>
        <input type="date" class="datespickers__input"
          v-model="from"
          :placeholder="from"
          :min="supermin"
          :max="to">
      </label>
      <label class="datespickers__label">
        <div>{{ $t('filters.to') }}</div>
        <input type="date" class="datespickers__input"
          v-model="to"
          :placeholder="to"
          :min="from"
          :max="supermax">
      </label>
    </div>

    <div class="dates__shorthands">
      <a class="dates__shorthand"
        v-for="shorthand in $config.dateShorthands"
        :key="shorthand.code"
        :class="{active: isActive(shorthand)}"
        :data-period="shorthand.period"
        :data-shift="shorthand.shift"
        @click.prevent="setPeriod(shorthand)">
        {{ $t(`dateShorthands.${shorthand.code}`) }}
        <div class="dates__shorthand-tip">
          {{ getShorthandTip(shorthand.period, shorthand.shift) }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterDates',

  data () {
    return {
      now: new Date()
    }
  },

  computed: {
    from: {
      get () {
        return this.$store.state.proxy.dates.from
      },
      set (value) {
        this.$store.commit('setProxyDates', { from: value })
        this.$emit('filterWasUpdated')
      }
    },

    to: {
      get () {
        return this.$store.state.proxy.dates.to
      },
      set (value) {
        this.$store.commit('setProxyDates', { to: value })
        this.$emit('filterWasUpdated')
      }
    },

    locale () {
      return this.$store.state.language
    },

    supermin () {
      return new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate() - 90).toISOString().slice(0, 10)
    },

    supermax () {
      return new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate() + 90).toISOString().slice(0, 10)
    }
  },

  methods: {
    setPeriod (shorthand) {
      const from = new Date()
      const to = new Date()

      from.setDate(from.getDate() + shorthand.shift)
      to.setDate(to.getDate() + shorthand.shift + shorthand.period)

      this.$store.commit('setProxyDates', {
        from: from.toISOString().slice(0, 10),
        to: to.toISOString().slice(0, 10)
      })
      this.$emit('filterWasUpdated')
    },

    isActive (shorthand) {
      const from = new Date()
      const to = new Date()

      from.setDate(from.getDate() + shorthand.shift)
      to.setDate(to.getDate() + shorthand.shift + shorthand.period)

      return from.toISOString().slice(0, 10) === this.$store.state.proxy.dates.from &&
        to.toISOString().slice(0, 10) === this.$store.state.proxy.dates.to
    },

    getShorthandTip (period, shift) {
      const from = new Date()
      const to = new Date()

      from.setDate(from.getDate() + shift)
      to.setDate(to.getDate() + shift + period)

      if (period === 0) {
        return from.toLocaleDateString(this.locale, { weekday: 'short', month: 'short', day: 'numeric' })
      } else {
        return from.toLocaleDateString(this.locale, { month: 'short', day: 'numeric' }) + ' – ' +
          to.toLocaleDateString(this.locale, { month: 'short', day: 'numeric' })
      }
    }
  }
}
</script>

<style scoped>
.datespickers__inputs {
  margin-bottom: 20px;
}

.datespickers__label {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.datespickers__input {
  width: 200px;
  height: 32px;
  padding: 8px 6px 8px 12px;
  margin-left: 12px;
  background-color: #fff;
  border: 1px solid #CFD1D5;
  border-radius: 24px;
  background: #fff;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  outline: none;
}

.datespickers__input:focus,
.datespickers__input:active {
  border: 1px solid #FFA408;
}

.dates__shorthands {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin: 0;
}

.dates__shorthand {
  flex-grow: 1;
  flex-shrink: 1;
  width: calc(100% / 2 - 6px);
  padding: 7px 11px;
  margin-bottom: 12px;
  border: 1px solid #B7BBC0;
  border-radius: 4px;
  background-color: #fff;
  color: #111;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  outline: none;
}

.dates__shorthand:nth-child(odd) {margin-right: 12px;}

.dates__shorthand.active {
  padding: 6px 10px;
  border: 2px solid #000000;
  background-color: #FBDDD3;
}

.dates__shorthand-tip {
  color: #606264;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
}
</style>
