<template>
    <div :class="['item__importance--' + level]">
      {{ $t(`importance.${level}_short`) }}
    </div>
</template>

<script>
export default {
  name: 'ItemImportance',
  props: {
    level: String
  }
}
</script>

<style scoped>
div {
  display: inline-block;
  box-sizing: border-box;
  min-width: 28px;
  padding: 0 4px;
  border-radius: 4px;
  vertical-align: middle;
  color: #000;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
}

.item__importance--high {
  background-color: #FF3300;
}
.item__importance--medium {
  background-color: #FFD600;
}
.item__importance--low {
  background-color: #669F33;
}
</style>
