<template>
  <div class="economcalendar-row item-row"
    :class="{'margined': !sameOptions.sameTime, 'obsolete': obsolete}"
    :data-id="event.id">
    <div class="time cell__time"
      :class="{'bordered': !sameOptions.sameTime}">{{ time }}</div>

    <div class="country cell__country" :class="{'bordered': !sameOptions.sameCountry}">
      <Flag width="22" height="16" class="flag"
        v-if="country"
        :id="country.toLowerCase()" />
      <span v-if="country">{{ $t(`countries.${country}`) }}</span>
    </div>

    <div class="expandable">
      <div class="economcalendar-column" :class="{'is-open': isOpened && !isDetailedView}">
        <div class="economcalendar-row">
          <div class="cell__importance">
            <ItemImportance :level="event.importance"/>
          </div>

          <div class="cell__event"
            v-if="!isDetailedView"
            :class="{'cell__event--opened': isOpened}"
            @click="openItem()">
            {{ event.title }}
          </div>
          <div class="cell__event cell__event--opened"
            v-else>
            {{ event.title }}
          </div>

          <div class="cell__impact">
            <ItemImpact :impact="event.impacts" v-if="!isOpened && event.impacts && event.impacts.length > 0"/>
          </div>

          <div class="cell__last">{{ last }}</div>

          <div class="cell__forecast">{{ forecast }}</div>

          <div class="cell__fact" :class="getFactColor(event.forecast, event.actual)">{{ fact }}</div>

          <div class="cell__expand"
            v-if="!isDetailedView"
            :class="{'is-open': isOpened}"
            @click="openItem()"></div>
        </div>

        <ItemDetailes class="economcalendar-row"
          v-if="isOpened || isDetailedView"
          :event="event" />
      </div>
    </div>
  </div>
</template>

<script>
import ItemImpact from '@/components/ItemImpact.vue'
import ItemImportance from '@/components/ItemImportance.vue'
import ItemDetailes from '@/components/ItemDetailes.vue'
import { Flag } from '@/ui'
import utils from '@/utils'
import '@/assets/sizes.css'

export default {
  name: 'Row',

  components: {
    ItemImpact,
    ItemDetailes,
    ItemImportance,
    Flag
  },

  props: {
    event: {
      type: Object,
      required: true
    },
    sameOptions: {
      type: Object,
      required: true
    },
    isDetailedView: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isOpened: this.isDetailedView
    }
  },

  computed: {
    time () {
      return !(this.sameOptions.sameTime) && this.event && this.event.timestamp ? this.event.timestamp.substring(11, 16) : ''
    },

    country () {
      return !(this.sameOptions.sameCountry) ? this.event.region : ''
    },

    obsolete () {
      if (this.isDetailedView) return false

      const now = new Date()
      const eventTime = new Date(utils.safeParseDate(this.event.timestamp))

      return eventTime < now
    },

    last () {
      if (!this.event.previous) return ''

      const sign = this.event.previous < 0 ? '−' : ''
      const currency = this.event.currency ? this.event.currency : ''
      const value = utils.formatNumber(Math.abs(this.event.previous))
      const unit = this.event.unit ? this.event.unit : ''

      return `${sign}${currency}${value}${unit}`
    },

    forecast () {
      if (!this.event.forecast) return ''

      const sign = this.event.forecast < 0 ? '−' : ''
      const currency = this.event.currency ? this.event.currency : ''
      const value = utils.formatNumber(Math.abs(this.event.forecast))
      const unit = this.event.unit ? this.event.unit : ''

      return `${sign}${currency}${value}${unit}`
    },

    fact () {
      if (!this.event.actual) return ''

      const sign = this.event.actual < 0 ? '−' : ''
      const currency = this.event.currency ? this.event.currency : ''
      const value = utils.formatNumber(Math.abs(this.event.actual))
      const unit = this.event.unit ? this.event.unit : ''

      return `${sign}${currency}${value}${unit}`
    }
  },

  methods: {
    openItem () {
      if (this.isDetailedView) this.isOpened = true
      else this.isOpened = !this.isOpened
    },

    getFactColor (forecast, fact) {
      return utils.getFactColor(forecast, fact)
    }
  }
}
</script>

<style scoped>
.economcalendar-row {
  align-items: flex-start;
  position: relative;
  min-height: 48px;
}

.obsolete .time,
.obsolete .country,
.obsolete .cell__importance,
.obsolete .cell__importance,
.obsolete .cell__event,
.obsolete .cell__impact >>> span,
.obsolete .cell__last,
.obsolete .cell__forecast,
.obsolete .cell__fact,
.obsolete .cell__expand {
  opacity: 0.6;
}

.economcalendar-row.obsolete + .economcalendar-row:not(.obsolete)::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 0;
  border-top: 1px solid #FF773D;
}
.economcalendar-row.obsolete + .economcalendar-row:not(.obsolete)::after {
  content: '';
  position: absolute;
  top: -4px;
  left: 0;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #FF773D;
}

.item-row {
  color: #606264;
  font-size: 12px;
  line-height: 16px;
}

.time,
.country {
  line-height: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flag {
  margin-right: 8px;
  vertical-align: middle;
}

.expandable {
  flex-grow: 1;
  min-width: 1%; /* IE11 hack */
}

.economcalendar-column.is-open {
  margin-bottom: 8px;
  background: #fff;
  box-shadow: 0px 4px 12px rgba(234, 87, 36, 0.05), 0px 4px 24px rgba(0, 0, 0, 0.16), 0px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
}

/* .margined .expandable,
.margined .bordered,
.margined .expandable {
  padding-top: 18px;
} */

.item-row:nth-of-type(n+3) .expandable,
.item-row:nth-of-type(n+3) .bordered {
  border-top: 1px solid #E0E2E4;
}

.cell__event {
  padding-top: 14px;
  padding-bottom: 14px;
  color: #111;
  font-weight: bold;
  line-height: 20px;
  cursor: pointer;
}

.cell__impact {
  font-weight: 500;
}

.cell__last,
.cell__forecast,
.cell__fact {
  color: #111;
  font-weight: 500;
}

.cell__importance,
.cell__impact,
.cell__last,
.cell__forecast,
.cell__fact,
.cell__expand {
  line-height: 48px;
  white-space: nowrap;
}

.cell__event,
.cell__last,
.cell__forecast,
.cell__fact {
  font-size: 14px;
}

.cell__fact--green {color: #53A642;}
.cell__fact--red {color: #f30;}

.cell__event.cell__event--opened {
  font-size: 18px;
}

.cell__expand {
  cursor: pointer;
}

.cell__expand::after {
  content: "";
  position: relative;
  top: -2px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border-right: 2px solid #909294;
  border-bottom: 2px solid #909294;
  transform: rotate(45deg);
  transition: all 0.2s ease;
}

.cell__expand.is-open::after {
  top: 2px;
  transform: rotate(-135deg);
}
</style>
