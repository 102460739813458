<template>
  <div class="list">
    <component :is="filtersSize" />

    <template v-if="!$store.state.isLoading">
      <component :is="listSize" />

      <div
        v-if="events.length > 0 && filters.dates.to"
        class="load-more"
      >
        <button
          :disabled="isLoadingDay || attemps === 7"
          :class="{ 'loading': isLoadingDay }"
          class="load-more-btn"
          @click="appendOneDay"
        >
          {{ $t('filters.load_more') }}
        </button>
      </div>

      <div
        v-if="events.length === 0"
        class="no-results"
      >
        {{ $t('filters.no_data') }}
      </div>
    </template>

    <Preloader v-else />
  </div>
</template>

<script>
import Filters from '@/components/Filters.vue'
import FiltersMobile from '@/components/mobile/Filters.vue'
import List from '@/components/List.vue'
import ListMobile from '@/components/mobile/List.vue'
import Preloader from '@/components/Preloader.vue'
import api from '@/api'

export default {
  name: 'All',

  components: {
    Filters,
    FiltersMobile,
    List,
    ListMobile,
    Preloader
  },

  data () {
    return {
      attemps: 0,
      isLoadingDay: false
    }
  },

  computed: {
    events () {
      return this.$store.state.events
    },

    language () {
      return this.$store.state.language
    },

    filters () {
      return this.$store.state.filters
    },

    filtersSize () {
      return this.$store.state.widgetWidth < 900
        ? 'FiltersMobile'
        : 'Filters'
    },

    listSize () {
      return this.$store.state.widgetWidth < 900
        ? 'ListMobile'
        : 'List'
    }
  },

  watch: {
    events () {
      this.attemps = 0
    }
  },

  created () {
    const from = new Date()
    const to = new Date()
    const offset = new Date().toTimeString().split('GMT')[1].split(' ')[0].split('')

    offset.splice(3, 0, ':')
    this.$store.commit('setTimezone', { timezone: offset.join('') })

    to.setDate(to.getDate() + 1)
    this.$store.commit('setDates', {
      from: from.toISOString().slice(0, 10),
      to: to.toISOString().slice(0, 10)
    })

    this.$store.dispatch('getEvents') // TODO process error and empty result
  },

  methods: {
    appendOneDay () {
      this.isLoadingDay = true
      const offset = window.pageYOffset

      // calculate next day
      const to = new Date(this.$store.state.filters.dates.to)
      to.setDate(to.getDate() + 1)
      const nextDate = to.toISOString().slice(0, 10)

      // load additional day
      api.getAll(this.$config.default.src, {
        start: nextDate,
        end: nextDate,
        lang: this.language,
        timezone: this.filters.timezone,
        importance: this.filters.importance.join(','),
        region: this.filters.regions.join(','),
        limit: this.filters.limit,
        offset: this.filters.offset
      })
        .then(data => {
          // change date
          this.$store.commit('setDates', { to: nextDate })
          // else append it to the store
          if (data.length > 0) {
            this.attemps = 0
            this.$store.commit('addOneDay', { day: data })
          } else if (this.attemps < 7) {
            // if the received day is empty try 7 times (one week) and after remove the button
            this.attemps++
            this.appendOneDay()
          }
        })
        .then(() => {
          window.scrollTo(0, offset) // to prevent the sticky bottom effect
          // cancel loading state
          this.isLoadingDay = false
        })
    }
  }
}
</script>

<style scoped>
.load-more {
  padding: 40px 10px;
  text-align: center;
}

.load-more-btn {
  position: relative;
  display: inline-block;
  padding: 8px 48px;
  background: #fff;
  border: 1px solid #111;
  border-radius: 4px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  font-weight: bold;
  color: #111;
  text-shadow: 0 -1px #fff;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  cursor: pointer;
  transition: background-color .2s ease;
}
.load-more-btn:hover {background: #f7f7f7;}
.load-more-btn:focus {box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.16);}
.load-more-btn:disabled {
  background: #fff;
  border-color: #999;
  color: #999;
  cursor: default;
}

.load-more-btn.loading {
  color: transparent;
  overflow: hidden;
}
.load-more-btn.loading::after {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  border-color: #f60 transparent;
  animation: rotate 1s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.no-results {
  padding: 50px 20px;
  text-align: center;
}
</style>
