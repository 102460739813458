<template>
  <div>
    <span v-html="substituteTip"></span>
  </div>
</template>

<script>
export default {
  name: 'ItemImpactDetailed',
  props: {
    impact: {
      type: Array,
      required: true
    }
  },
  computed: {
    pips () {
      return this.impact[0].value
    },
    code () {
      return this.impact[0].name.replace(/\//g, '')
    },
    substituteTip () {
      const pipsStr = `<strong>${this.$t('impact.pips', { pips: this.pips })}</strong>`

      return this.$t('impact.measured', { pips: pipsStr })
    }
  }
}
</script>

<style scoped>
div {
  margin-bottom: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  background: #F0F2F4;
  color: #606264;
  font-size: 12px;
  line-height: 16px;
}
</style>
