<template>
  <div class="filter-timezones">
    <div class="filter-timezones__wrapper">
      <div class="filter-timezones__list">
        <div class="filter-timezones__item"
          v-for="tz in timezones"
          :key="tz.label"
          :class="{'filter-timezones__item--selected': tz.offset === timezone}"
          @click="setTimezone(tz.offset)">
          <label>
            <div class="filter-timezones__item-shift">
              GMT {{ tz.offset }}
            </div>
            <div class="filter-timezones__item-name">{{ $t(`timezones.${tz.tzCode}`) }}</div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { minimalTimezoneSet } from 'compact-timezone-list'

export default {
  name: 'FilterTimezones',

  data () {
    return {
      timezones: minimalTimezoneSet
    }
  },

  computed: {
    timezone () {
      return this.$store.state.proxy.timezone
    }
  },

  methods: {
    setTimezone (offset) {
      this.$store.commit('setProxyTimezone', { timezone: offset })
      this.$emit('filterWasUpdated')
    }
  },

  mounted () {
    const selected = document.querySelector('.filter-timezones__item--selected')

    selected.scrollIntoView({
      block: 'center',
      inline: 'nearest',
      behavior: 'smooth'
    })
  }
}
</script>

<style scoped>
.filter-timezones {
  background-color: #fff;
  border: 1px solid #CFD1D5;
  color: #111;
  font-size: 13px;
  line-height: 16px;
}

.filter-timezones__wrapper { /* Just to round scroll bar's borders */
  overflow: hidden;
  border-radius: 3px;
}

.filter-timezones__list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 296px;
  max-height: 280px;
  overflow: auto;
}

.filter-timezones__item {
  position: relative;
  padding: 12px;
  padding-left: 40px;
  border-bottom: 0.5px solid #E0E2E4;
  cursor: pointer;
  white-space: nowrap;
}
.filter-timezones__item:first-child {border-radius: 3px 3px 0 0;}
.filter-timezones__item:last-child {
  border-bottom: 0;
  border-radius: 0 0 3px 3px;
}

label {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.filter-timezones__item--selected {background-color: #F2F4F6;}

.filter-timezones__item::before,
.filter-timezones__item--selected::after {
  content: '';
  position: absolute;
  border-radius: 50%;
}
.filter-timezones__item::before {
  top: calc(50% - 8px);
  left: 12px;
  width: 14px;
  height: 14px;
  background: linear-gradient(180deg, #FEFFFF 0%, #F0F2F4 100%);
  border: 1px solid #C0C2C4;
}
.filter-timezones__item--selected::after {
  top: calc(50% - 3px);
  left: 17px;
  width: 6px;
  height: 6px;
  background-color: #000;
}

.filter-timezones__item-shift {
  margin-right: 8px;
}
.filter-timezones__item-name {
  margin-right: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606264;
}
</style>
