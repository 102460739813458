<template>
  <div class="economcalendar-column"
    :data-id="event.id"
    :class="{'obsolete': obsolete}">
    <div class="item-wrapper">
      <div class="economcalendar-row row--time-country"
        @click="isOpened = !isOpened">
        <div class="time">{{ event.timestamp.substring(11, 16) }}</div>

        <div class="country">
          <Flag width="17" height="12" class="flag" :id="country.toLowerCase()" />
          <span>{{ $t(`countries.${country}`) }}</span>
        </div>

        <div class="expand"></div>
      </div>

      <div class="name"
        :class="'importance--' + event.importance"
        @click="isOpened = !isOpened">
        {{ event.title }}
      </div>

      <div class="economcalendar-row row-numbers"
        @click="isOpened = !isOpened">
        <div class="last">
          <div class="label">{{ $t('caption.last') }}</div>
          <div class="value">{{ last }}</div>
        </div>

        <div class="forecast">
          <div class="label">{{ $t('caption.forecast') }}</div>
          <div class="value">{{ forecast }}</div>
        </div>

        <div class="fact">
          <div class="label">{{ $t('caption.fact') }}</div>
          <div class="value" :class="getFactColor(event.forecast, event.actual)">{{ fact }}</div>
        </div>

        <div class="impact">
          <div class="label">{{ $t('caption.impacts') }}</div>
          <ItemImpact class="value" data-icid="button"
            :data-icid-id="`btn_wdg_eccal_goto_${event.impacts[0].name.toLowerCase().replace(/\//g, '')}`"
            :impact="event.impacts"
            v-if="event.impacts.length > 0"
            @click.native.stop="followLink(`https://${$store.state.app}${event.impacts[0].deeplink}`)"/>
          <span v-if="event.impacts.length === 0">–</span>
        </div>
      </div>
    </div>

    <ItemDetailes
      v-if="isOpened"
      :event="event"
      @closeEvent="close()"/>
  </div>
</template>

<script>
import ItemImpact from '@/components/mobile/ItemImpact.vue'
import ItemDetailes from '@/components/mobile/ItemDetailes.vue'
import { Flag } from '@/ui'
import utils from '@/utils'

export default {
  name: 'Row',

  components: {
    ItemImpact,
    ItemDetailes,
    Flag
  },

  props: {
    event: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      isOpened: false
    }
  },

  computed: {
    country () {
      return this.event.region
    },

    obsolete () {
      const now = new Date()
      const eventTime = new Date(utils.safeParseDate(this.event.timestamp))

      return eventTime < now
    },

    last () {
      if (!this.event.previous) return '–'

      const sign = this.event.previous < 0 ? '−' : ''
      const currency = this.event.currency ? this.event.currency : ''
      const value = utils.formatNumber(Math.abs(this.event.previous))
      const unit = this.event.unit ? this.event.unit : ''

      return `${sign}${currency}${value}${unit}`
    },

    forecast () {
      if (!this.event.forecast) return '–'

      const sign = this.event.forecast < 0 ? '−' : ''
      const currency = this.event.currency ? this.event.currency : ''
      const value = utils.formatNumber(Math.abs(this.event.forecast))
      const unit = this.event.unit ? this.event.unit : ''

      return `${sign}${currency}${value}${unit}`
    },

    fact () {
      if (!this.event.actual) return '–'

      const sign = this.event.actual < 0 ? '−' : ''
      const currency = this.event.currency ? this.event.currency : ''
      const value = utils.formatNumber(Math.abs(this.event.actual))
      const unit = this.event.unit ? this.event.unit : ''

      return `${sign}${currency}${value}${unit}`
    }
  },

  methods: {
    close () {
      this.isOpened = false
    },

    followLink (link) {
      window.open(link, '_blank')
    },

    getFactColor (forecast, fact) {
      return utils.getFactColor(forecast, fact)
    }
  }
}
</script>

<style scoped>
.economcalendar-column {
  position: relative;
  padding: 12px 0;
  margin: 0 20px;
  border-bottom: 1px solid #E0E2E4;
}
.economcalendar-column:last-child {
  border-bottom: 0;
}

.item-wrapper {
  color: #606264;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
}

.obsolete .item-wrapper {
  opacity: 0.6;
}

.economcalendar-column.obsolete + .economcalendar-column:not(.obsolete)::before {
  content: '';
  position: absolute;
  top: -1px;
  width: calc(100% + 20px);
  height: 0;
  border-top: 1px solid #FF773D;
}
.economcalendar-column.obsolete + .economcalendar-column:not(.obsolete)::after {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #FF773D;
}

.economcalendar-row {
  width: 100%;
  align-items: center;
}

.row--time-country {
  margin-bottom: 10px;
}

.time,
.country {
  line-height: 20px;
  white-space: nowrap;
}

.time {
  flex-basis: 27%;
}

.flag  {
  margin-right: 8px;
  vertical-align: middle;
  margin-top: -3px;
}

.name {
  position: relative;
  color: #111;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.importance--high::before,
.importance--medium::before,
.importance--low::before {
  content: '';
  position: absolute;
  left: -12px;
  top: 13px;
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 3px;
}
.importance--high::before {background-color: #FF3300;}
.importance--medium::before {background-color: #FFD600;}
.importance--low::before {background-color: #53A642;}

.row-numbers {
  align-items: flex-start;
  margin-top: 8px;
}

.last,
.forecast,
.fact {
  flex-basis: 27%;
}

.impact {
  flex-grow: 1;
  white-space: nowrap;
  text-align: right;
}

.value {
  color: #111;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.cell__fact--green {color: #53A642;}
.cell__fact--red {color: #f30;}

.impact .value {
  font-size: 12px;
  margin-left: -40px;
}

.expand {
  margin-left: auto;
}

.expand::after {
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  border-right: 2px solid #909294;
  border-bottom: 2px solid #909294;
  transform: rotate(-45deg);
}
</style>
