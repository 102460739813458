<template>
  <div id="econom-calendar">
    <router-view/>
  </div>
</template>

<script>
import utils from '@/utils'

export default {
  name: 'EconomCalendar',

  props: {
    lang: {
      type: String,
      required: true
    },

    headerOffset: {
      type: String,
      default: '0'
    }
  },

  methods: {
    updateWidgetWidth () {
      this.$store.commit('setWidgetWidth', { widgetWidth: this.$el.clientWidth })
    }
  },

  created () {
    window.addEventListener('resize', utils.debounce(this.updateWidgetWidth, 50))

    if (this.$i18n.availableLocales.indexOf(this.lang) === -1) {
      this.$i18n.locale = this.$i18n.fallbackLocale
    } else {
      this.$i18n.locale = this.lang
    }

    this.$store.commit('setLanguage', { language: this.$i18n.locale })
    this.$store.commit('setApp', { app: this.$config.default.app })
  },

  mounted () {
    this.updateWidgetWidth()

    const headerOffsetToInt = parseInt(this.headerOffset)

    if (isNaN(headerOffsetToInt)) { // looks like it's a CSS selector
      const element = document.querySelector(this.headerOffset)

      if (element) {
        this.$store.commit('setHeaderOffset', { headerOffset: element.offsetHeight })
      }
    } else { // it's a number
      this.$store.commit('setHeaderOffset', { headerOffset: headerOffsetToInt })
    }
  }
}
</script>

<style>
#econom-calendar {
  position: relative;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
