<template>
  <div>
    <ItemDetailes
      :event="event"
      @closeEvent="goToList()"/>
  </div>
</template>

<script>
import ItemDetailes from '@/components/mobile/ItemDetailes.vue'

export default {
  name: 'Event',

  components: {
    ItemDetailes
  },

  props: {
    event: {
      type: Object,
      required: true
    }
  },

  methods: {
    goToList () {
      this.$router.push({ path: '/' })
    }
  }
}
</script>
