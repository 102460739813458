<template>
  <div class="date" :style="styleObject">
    {{ formatted }}
  </div>
</template>

<script>
// import utils from '@/utils'

export default {
  name: 'DateCaption',

  props: {
    date: {
      type: String,
      required: true
    },

    isDetailedView: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    locale () {
      return this.$store.state.language
    },

    formatted () {
      const date = this.date.slice(0, 10) // Here we cut our date to make it UTC w/o any offset
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        // hour12: false,
        // hour: '2-digit',
        // minute: '2-digit',
        timeZone: 'UTC' // here we set our date as UTC
      }
      const dateTimeFormat = new Intl.DateTimeFormat(this.locale, options)

      return dateTimeFormat.format(new Date(date))
    },

    headerOffset () {
      return this.$store.state.headerOffset
    },

    styleObject () {
      const styles = {
        position: 'sticky',
        zIndex: 1,
        top: `${this.headerOffset + 99}px`
      }

      return this.isDetailedView ? {} : styles
    }
  }
}
</script>

<style scoped>
.date {
  padding: 0 16px;
  border-top: 1px solid #E0E2E4;
  border-bottom: 2px solid #E0E2E4;
  background-color: #F0F2F4;
  font-size: 14px;
  line-height: 40px;
  font-weight: 500;
}
</style>
