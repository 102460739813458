<template>
  <div class="list">
    <div v-for="day in events" :key="`${day[0].timestamp}-date-row`">
      <DateCaption :date="day[0].timestamp" />

      <Row v-for="event in day" :key="event.id"
        :event="event" />
    </div>
  </div>
</template>

<script>
import DateCaption from '@/components/mobile/DateCaption.vue'
import Row from '@/components/mobile/Row.vue'
import utils from '@/utils'

export default {
  name: 'List',

  components: {
    DateCaption,
    Row
  },

  computed: {
    events () {
      return utils.sortInDays(this.$store.state.events)
    }
  }
}
</script>
