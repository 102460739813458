<template>
  <div class="wrapper">
    <Preloader v-if="$store.state.isLoading" />

    <template v-else>
      <div class="economcalendar-row">
        <button class="back" @click="$emit('closeEvent')">{{ $t('detailed.back') }}</button>
      </div>

      <div class="no-results"
        v-if="event.id === undefined">{{ $t('filters.no_data') }}</div>

      <template v-else>
        <div class="economcalendar-row">
          <div class="time">{{ event.timestamp.substring(11, 16) }}</div>

          <div class="date">{{ getDateLabel(event.timestamp) }}</div>

          <div class="country">
            <Flag width="17" height="12" class="flag"
              :id="event.region.toLowerCase()" />
            <span>{{ $t(`countries.${event.region}`) }}</span>
          </div>
        </div>

        <div class="name">
          {{ event.title }}
        </div>

        <div class="importance"
          :class="'importance--' + event.importance">
          {{ $t(`importance.${event.importance}_full`) }}
        </div>

        <div class="economcalendar-row row-numbers">
          <div class="last">
            <div class="label">{{ $t('caption.last') }}</div>
            <div class="value">{{ last }}</div>
          </div>

          <div class="forecast">
            <div class="label">{{ $t('caption.forecast') }}</div>
            <div class="value">{{ forecast }}</div>
          </div>

          <div class="fact">
            <div class="label">{{ $t('caption.fact') }}</div>
            <div class="value" :class="getFactColor(event.forecast, event.actual)">{{ fact }}</div>
          </div>

          <div class="impact">
            <div class="label">{{ $t('caption.impacts') }}</div>
            <ItemImpact class="value" data-icid="button"
              v-if="event.impacts && event.impacts.length > 0"
              :impact="event.impacts"
              :data-icid-id="`btn_wdg_eccal_goto_${event.impacts[0].name.toLowerCase().replace(/\//g, '')}`"
              @click.native="followLink(`https://${$store.state.app}${event.impacts[0].deeplink}`)"/>
            <span v-else>–</span>
          </div>
        </div>

        <ItemDetailesImpact
          v-if="event.impacts && event.impacts.length > 0"
          :impact="event.impacts" />

        <div class="links economcalendar-row">
          <div>
            <button class="btn-switch"
              :class="{'btn-switch--active': chartIsOpen}"
              @click="showHistory('chart')"><icon-chart /></button>
            <button class="btn-switch"
              :class="{'btn-switch--active': tableIsOpen}"
              @click="showHistory('table')"><icon-table /></button>
          </div>

          <div v-click-outside="close">
            <button class="btn-trigger"
              :class="{'active': dropdown}"
              @click="dropdown = !dropdown"><icon-actions /></button>

            <div class="links-dropdown"
              v-show="dropdown">
              <button class="btn-action"
                @click="copyEventAddress(event.id, $event)"><icon-copy /> {{ $t('detailed.copy') }}</button>
              <button class="btn-action"
                @click="downloadFile('xlsx')"><icon-download /> {{ $t('detailed.download_xls') }}</button>
              <button class="btn-action"
                @click="downloadFile('csv')"><icon-download /> {{ $t('detailed.download_csv') }}</button>
            </div>
          </div>
        </div>

        <HistoryChart v-if="chartIsOpen && historyData" :loaded="historyLoaded" :history="historyData" />
        <HistoryTable v-if="tableIsOpen && historyData" :loaded="historyLoaded" :history="[...historyData].reverse()" />

        <div class="description">{{ event.description }}</div>

        <div class="cta" v-if="event.impacts && event.impacts.length > 0">
          <a class="cta-link" href="#" data-icid="button"
            :data-icid-id="`btn_wdg_eccal_goto_${event.impacts[0].name.toLowerCase().replace(/\//g, '')}`"
            @click.prevent="followLink(`https://${$store.state.app}${event.impacts[0].deeplink}`)">
            {{ $t('impact.goto', {instrument: event.impacts[0].name}) }}
            <icon-arrow />
          </a>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import ItemDetailesImpact from '@/components/mobile/ItemDetailesImpact.vue'
import ItemImpact from '@/components/mobile/ItemImpact.vue'
import Preloader from '@/components/Preloader.vue'
import HistoryChart from '@/components/HistoryChart.vue'
import HistoryTable from '@/components/HistoryTable.vue'
import { Flag, IconArrow, IconChart, IconTable, IconCopy, IconDownload, IconActions } from '@/ui'
import utils from '@/utils'
import api from '@/api'
import '@/assets/sizes.css'

export default {
  name: 'ItemDetailes',

  components: {
    ItemDetailesImpact,
    ItemImpact,
    HistoryChart,
    HistoryTable,
    Flag,
    IconArrow,
    IconChart,
    IconTable,
    IconCopy,
    IconDownload,
    IconActions,
    Preloader
  },

  props: {
    event: {
      type: Object,
      required: true
    }
  },

  computed: {
    last () {
      if (!this.event.previous) return '–'

      const sign = this.event.previous < 0 ? '−' : ''
      const currency = this.event.currency ? this.event.currency : ''
      const value = utils.formatNumber(Math.abs(this.event.previous))
      const unit = this.event.unit ? this.event.unit : ''

      return `${sign}${currency}${value}${unit}`
    },

    forecast () {
      if (!this.event.forecast) return '–'

      const sign = this.event.forecast < 0 ? '−' : ''
      const currency = this.event.currency ? this.event.currency : ''
      const value = utils.formatNumber(Math.abs(this.event.forecast))
      const unit = this.event.unit ? this.event.unit : ''

      return `${sign}${currency}${value}${unit}`
    },

    fact () {
      if (!this.event.actual) return '–'

      const sign = this.event.actual < 0 ? '−' : ''
      const currency = this.event.currency ? this.event.currency : ''
      const value = utils.formatNumber(Math.abs(this.event.actual))
      const unit = this.event.unit ? this.event.unit : ''

      return `${sign}${currency}${value}${unit}`
    }
  },

  data () {
    return {
      historyData: [],
      historyLoaded: false,
      chartIsOpen: false,
      tableIsOpen: false,
      dropdown: false
    }
  },

  methods: {
    close (event) {
      this.dropdown = false
    },

    getDateLabel (date) { // IE11 hack here
      return new Date(utils.safeParseDate(date)).toLocaleDateString(this.$store.state.language, { month: 'short', day: 'numeric', year: 'numeric' })
    },

    followLink (link) {
      window.open(link, '_blank')
    },

    copyEventAddress (id, event) {
      const link = this.$router.resolve({ name: 'Single', params: { id: id } }).href

      return utils.copyEventAddress(link, event.target)
    },

    showHistory (type) {
      if (this.historyData.length === 0) {
        api.getEventData({
          id: this.event.id,
          lang: this.$store.state.language,
          src: this.$config.default.src
        })
          .then(data => {
            this.historyData = data
            this.historyLoaded = true
          })
      }

      if (type === 'chart') {
        this.tableIsOpen = false
        this.chartIsOpen = !this.chartIsOpen
      }
      if (type === 'table') {
        this.chartIsOpen = false
        this.tableIsOpen = !this.tableIsOpen
      }
    },

    downloadFile (type) {
      utils.download(`${this.$config.default.src}/api/events/${this.event.id}/history/?format=${type}`, `${this.event.title}.${type}`)
    },

    getFactColor (forecast, fact) {
      return utils.getFactColor(forecast, fact)
    }
  },

  created () {
    document.body.style.overflow = 'hidden'
  },

  destroyed () {
    document.body.style.overflow = ''
  }
}
</script>

<style scoped>
body {
  overflow: hidden;
}

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  padding: 16px 20px;
  background-color: #fff;
  overflow: auto;
  color: #606264;
  font-size: 12px;
  line-height: 16px;
}

.back {
  margin-bottom: 22px;
  padding-left: 2px;
  background-color: #fff;
  border: none;
  color: #1192E8;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
}

.back::before {
  content: "";
  position: relative;
  top: -1px;
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-right: 8px;
  border-left: 2px solid #1192E8;
  border-bottom: 2px solid #1192E8;
  transform: rotate(45deg);
}

.name {
  margin-top: 8px;
  margin-bottom: 8px;
  color: #111;
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
}

.importance {
  margin-bottom: 12px;
  color: #111;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.importance--high::before,
.importance--medium::before,
.importance--low::before {
  content: '';
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 3px;
  border-radius: 3px;
}
.importance--high::before {background-color: #FF3300;}
.importance--medium::before {background-color: #FFD600;}
.importance--low::before {background-color: #53A642;}

.row-numbers {
  align-items: flex-start;
  margin-top: 8px;
  margin-bottom: 12px;
}

.time,
.date,
.last,
.forecast,
.fact {
  flex-basis: 27%;
}

.country,
.impact {
  flex-grow: 1;
  white-space: nowrap;
  text-align: right;
}

.flag {
  margin-right: 5px;
  vertical-align: top;
  margin-top: 2px;
}

.value {
  color: #111;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.cell__fact--green {color: #53A642;}
.cell__fact--red {color: #f30;}

.impact .value {
  font-size: 12px;
  margin-left: -40px;
}

.links {
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  margin-bottom: 12px;
  border-top: 1px solid #E0E2E4;
}

.btn-switch,
.btn-action {
  cursor: pointer;
  outline: none;
}

.btn-switch {
  padding: 3px 7px;
  margin-right: 8px;
  background: #fff;
  border: 1px solid #B7BBC0;
  border-radius: 4px;
}
.btn-switch:hover {
  background: #f7f7f7;
}

.btn-trigger {
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background: none;
  text-align: center;
  cursor: pointer;
  outline: none;
}

.btn-trigger.active {
  background-color: #F0F2F4;
}

.links-dropdown {
  position: absolute;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(0, 0, 0, 0.16), 0px 4px 12px rgba(234, 87, 36, 0.05);
  border-radius: 4px;
}

.btn-switch--active,
.btn-switch--active:hover {
  background: #EA5724;
  border: 1px solid #EA5724;
}

.btn-switch svg,
.btn-action svg {
  vertical-align: middle;
  transition: all 0.5s ease;
}

.btn-switch--active svg,
.btn-action--active svg {
  fill: #fff;
}

.btn-action {
  width: 100%;
  padding: 8px 12px 4px;
  margin: 0;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #E0E2E4;
  color: #1192E8;
  text-align: left;
  outline: none;
  transition: all 0.5s ease;
}
.btn-action:hover {
  background: #F0F2F4;
}
.btn-action--active,
.btn-action--active:hover {
  background-color: #1192E8;
  color: #fff;
}

.btn-action:first-child {
  border-radius: 4px 4px 0 0;
}
.btn-action:last-child {
  border-radius: 0 0 4px 4px;
  border-bottom: 0;
}

.btn-action svg {
  margin: -2px 0 2px;
  vertical-align: middle;
}

.description {
  margin: 16px 0 76px;
}

.cta {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px 20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12), inset 0px 0.5px 0px rgba(0, 0, 0, 0.12);
}

.cta-link {
  display: block;
  width: 100%;
  padding: 14px 32px;
  box-sizing: border-box;
  background: #EA5724;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  border: 0;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.cta-link:hover {
  opacity: 0.9;
}

.cta-link svg {
  vertical-align: middle;
}

.no-results {
  padding: 50px 20px;
  text-align: center;
}
</style>
