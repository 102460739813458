<template>
  <svg width="22" height="16" viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg">
    <template v-if="level === 'low'">
      <rect width="6" height="8" fill="#606264" x="0" y="8"/>
      <rect width="6" height="12" fill="#E0E2E4" x="8" y="4"/>
      <rect width="6" height="16" fill="#E0E2E4" x="16" y="0"/>
    </template>
    <template v-if="level === 'medium'">
      <rect width="6" height="8" fill="#606264" x="0" y="8"/>
      <rect width="6" height="12" fill="#606264" x="8" y="4"/>
      <rect width="6" height="16" fill="#E0E2E4" x="16" y="0"/>
    </template>
    <template v-if="level === 'high'">
      <rect width="6" height="8" fill="#606264" x="0" y="8"/>
      <rect width="6" height="12" fill="#606264" x="8" y="4"/>
      <rect width="6" height="16" fill="#606264" x="16" y="0"/>
    </template>
  </svg>
</template>

<script>
export default {
  name: 'IconImportanceInverted',

  props: {
    level: {
      type: String,
      required: true
    }
  }
}
</script>
