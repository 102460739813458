export default {
  en: {
    filters: {
      filters: 'Filters',
      all_countries: 'All countries',
      search: 'Search',
      all_importances: 'All events',
      importance: 'Importance',
      countries: 'Countries',
      dates: 'Date range',
      date: 'Dates',
      gmt: 'GMT',
      all_from: 'All upcoming from ',
      all_till: 'All upcoming till ',
      from: 'From',
      to: 'To',
      clear: 'Clear',
      load_more: 'Load more',
      cancel: 'Cancel',
      apply: 'Apply',
      no_data: 'No data for the selected period'
    },
    importance: {
      low: 'Low',
      low_short: 'Low',
      low_full: 'Low Importance',
      low_tip: 'Historically less market-moving event except in the most extraordinary of circumstances',
      medium: 'Medium',
      medium_short: 'Med',
      medium_full: 'Medium Importance',
      medium_tip: 'Relatively less likely to force major market moves except on outsized surprises',
      high: 'High',
      high_short: 'High',
      high_full: 'High Importance',
      high_tip: 'Which have historically sparked market volatility'
    },
    countries: {
      US: 'United States',
      AU: 'Australia',
      AT: 'Austria',
      BE: 'Belgium',
      BR: 'Brazil',
      CA: 'Canada',
      CN: 'China',
      CY: 'Cyprus',
      CZ: 'Czech Republic',
      DK: 'Denmark',
      EA: 'Euro Area',
      FI: 'Finland',
      FR: 'France',
      DE: 'Germany',
      GR: 'Greece',
      HK: 'Hong Kong',
      HU: 'Hungary',
      IN: 'India',
      ID: 'Indonesia',
      IE: 'Ireland',
      IT: 'Italy',
      JP: 'Japan',
      LU: 'Luxembourg',
      MX: 'Mexico',
      NL: 'Netherlands',
      NZ: 'New Zealand',
      NO: 'Norway',
      PL: 'Poland',
      PT: 'Portugal',
      RO: 'Romania',
      RU: 'Russia',
      SG: 'Singapore',
      ZA: 'South Africa',
      KR: 'South Korea',
      ES: 'Spain',
      SE: 'Sweden',
      CH: 'Switzerland',
      TR: 'Turkey',
      GB: 'United Kingdom'
    },
    regions: {
      g7: 'G7',
      eu: 'European Union',
      la: 'Latin America',
      as: 'Asia',
      all: 'All'
    },
    dateShorthands: {
      today: 'Today',
      tomorrow: 'Tomorrow',
      twodays: '2 days',
      week: 'This week',
      nextweek: 'Next week',
      month: 'This month'
    },
    timezones: {
      'Pacific/Pago_Pago': 'Pacific/Pago_Pago',
      'Pacific/Honolulu': 'Pacific/Honolulu',
      'Pacific/Tahiti': 'Pacific/Tahiti',
      'America/Anchorage': 'America/Anchorage',
      'America/Los_Angeles': 'America/Los_Angeles',
      'America/Denver': 'America/Denver',
      'America/Chicago': 'America/Chicago',
      'America/New_York': 'America/New_York',
      'America/Halifax': 'America/Halifax',
      'America/Argentina/Buenos_Aires': 'America/Argentina/Buenos_Aires',
      'America/Sao_Paulo': 'America/Sao_Paulo',
      'Atlantic/Azores': 'Atlantic/Azores',
      'Europe/London': 'Europe/London',
      'Europe/Berlin': 'Europe/Berlin',
      'Europe/Helsinki': 'Europe/Helsinki',
      'Europe/Istanbul': 'Europe/Moscow',
      'Asia/Dubai': 'Asia/Dubai',
      'Asia/Kabul': 'Asia/Kabul',
      'Indian/Maldives': 'Indian/Maldives',
      'Asia/Calcutta': 'Asia/Calcutta',
      'Asia/Kathmandu': 'Asia/Kathmandu',
      'Asia/Dhaka': 'Asia/Dhaka',
      'Indian/Cocos': 'Indian/Cocos',
      'Asia/Bangkok': 'Asia/Bangkok',
      'Asia/Hong_Kong': 'Asia/Hong_Kong',
      'Asia/Pyongyang': 'Asia/Pyongyang',
      'Asia/Tokyo': 'Asia/Tokyo',
      'Australia/Darwin': 'Australia/Darwin',
      'Australia/Brisbane': 'Australia/Brisbane',
      'Australia/Adelaide': 'Australia/Adelaide',
      'Australia/Sydney': 'Australia/Sydney',
      'Pacific/Nauru': 'Pacific/Nauru',
      'Pacific/Auckland': 'Pacific/Auckland',
      'Pacific/Kiritimati': 'Pacific/Kiritimati'
    },
    caption: {
      time: 'Time',
      date: 'Date',
      country: 'Country',
      importance: 'Importance',
      importance_tip: 'The intensity of the event, shows how strong this event influense on the markets, activity and behaviors of traders',
      event: 'Event',
      impacts: 'Impacts',
      impacts_tip: 'Shows the instrument wich activly reacts by the event',
      last: 'Last',
      last_tip: 'The previous data',
      forecast: 'Forecast',
      forecast_tip: 'The forecast data',
      fact: 'Fact',
      fact_tip: 'The fact data'
    },
    impact: {
      on: 'This report has an impact on',
      pips: '{pips} pips',
      measured: 'We measured {pips} volatility in the 4 hours after past events.',
      goto_instrument: 'Go to {instrument} instrument',
      goto: 'Go to {instrument}'
    },
    detailed: {
      back: 'Back to calendar',
      copy: 'Copy URL',
      download_xls: 'Download XLS',
      download_csv: 'Download CSV',
      date: 'Date',
      showing: 'Showing <b>{0}</b> from <b>{1}</b> entries',
      actual: 'Actual',
      forecast: 'Forecast'
    }
  },
  de: {
    filters: {
      filters: 'Filter',
      all_countries: 'Alle Länder',
      search: 'Suche',
      all_importances: 'Alle Ereignisse',
      importance: 'Wichtigkeit',
      countries: 'Länder',
      dates: 'Zeitraum',
      date: 'Datum',
      gmt: 'GMT',
      all_from: 'Alle bevorstehenden ab',
      all_till: 'Alle bevorstehenden bis',
      from: 'Ab',
      to: 'Bis',
      clear: 'Löschen',
      load_more: 'Mehr laden',
      cancel: 'Stornieren',
      apply: 'Anwenden',
      no_data: 'Keine Daten für den ausgewählten Zeitraum'
    },
    importance: {
      low: 'Gering',
      low_short: 'Grg',
      low_full: 'Geringe Wichtigkeit',
      low_tip: 'Historisch gesehen ein weniger marktbeeinflussendes Ereignis, außer unter den außergewöhnlichsten Umständen',
      medium: 'Mittel',
      medium_short: 'Med',
      medium_full: 'Mittlere Wichtigkeit',
      medium_tip: 'Relativ geringe Wahrscheinlichkeit, dass größere Marktbewegungen erzwungen werden, außer bei sehr großen Überraschungen',
      high: 'Hoch',
      high_short: 'Hoch',
      high_full: 'Hohe Wichtigkeit',
      high_tip: 'Haben in der Vergangenheit eine Marktvolatilität ausgelöst'
    },
    countries: {
      US: 'Vereinigte Staaten',
      AU: 'Australien',
      AT: 'Österreich',
      BE: 'Belgien',
      BR: 'Brasilien',
      CA: 'Kanada',
      CN: 'China',
      CY: 'Zypern',
      CZ: 'Tschechische Republik',
      DK: 'Dänemark',
      EA: 'Eurozone',
      FI: 'Finnland',
      FR: 'Frankreich',
      DE: 'Deutschland',
      GR: 'Griechenland',
      HK: 'Hongkong',
      HU: 'Ungarn',
      IN: 'Indien',
      ID: 'Indonesien',
      IE: 'Irland',
      IT: 'Italien',
      JP: 'Japan',
      LU: 'Luxemburg',
      MX: 'Mexiko',
      NL: 'Niederlande',
      NZ: 'Neuseeland',
      NO: 'Norwegen',
      PL: 'Polen',
      PT: 'Portugal',
      RO: 'Rumänien',
      RU: 'Russland',
      SG: 'Singapur',
      ZA: 'Südafrika',
      KR: 'Südkorea',
      ES: 'Spanien',
      SE: 'Schweden',
      CH: 'Schweiz',
      TR: 'Türkei',
      GB: 'Großbritannien'
    },
    regions: {
      g7: 'G7',
      eu: 'Europäische Union',
      la: 'Lateinamerika',
      as: 'Asien',
      all: 'Alle'
    },
    dateShorthands: {
      today: 'Heute',
      tomorrow: 'Morgen',
      twodays: '2 Tage',
      week: 'Diese Woche',
      nextweek: 'Nächste Woche',
      month: 'Diesen Monat'
    },
    timezones: {
      'Pacific/Pago_Pago': 'Pazifik/Pago_Pago',
      'Pacific/Honolulu': 'Pazifik/Honolulu',
      'Pacific/Tahiti': 'Pazifik/Tahiti',
      'America/Anchorage': 'Amerika/Anchorage',
      'America/Los_Angeles': 'Amerika/Los_Angeles',
      'America/Denver': 'Amerika/Denver',
      'America/Chicago': 'Amerika/Chicago',
      'America/New_York': 'Amerika/New_York',
      'America/Halifax': 'Amerika/Halifax',
      'America/Argentina/Buenos_Aires': 'Amerika/Argentinien/Buenos_Aires',
      'America/Sao_Paulo': 'Amerika/Sao_Paulo',
      'Atlantic/Azores': 'Atlantik/Azoren',
      'Europe/London': 'Europa/London',
      'Europe/Berlin': 'Europa/Berlin',
      'Europe/Helsinki': 'Europa/Helsinki',
      'Europe/Istanbul': 'Europa/Moskau',
      'Asia/Dubai': 'Asien/Dubai',
      'Asia/Kabul': 'Asien/Kabul',
      'Indian/Maldives': 'Indien/Malediven',
      'Asia/Calcutta': 'Asien/Kallkutta',
      'Asia/Kathmandu': 'Asien/Kathmandu',
      'Asia/Dhaka': 'Asien/Dhaka',
      'Indian/Cocos': 'Indien/Cocos',
      'Asia/Bangkok': 'Asien/Bangkok',
      'Asia/Hong_Kong': 'Asien/Hong_Kong',
      'Asia/Pyongyang': 'Asien/Pyongyang',
      'Asia/Tokyo': 'Asien/Tokyo',
      'Australia/Darwin': 'Australien/Darwin',
      'Australia/Brisbane': 'Australien/Brisbane',
      'Australia/Adelaide': 'Australien/Adelaide',
      'Australia/Sydney': 'Australien/Sydney',
      'Pacific/Nauru': 'Pazifik/Nauru',
      'Pacific/Auckland': 'Pazifik/Auckland',
      'Pacific/Kiritimati': 'Pazifik/Kiritimati'
    },
    caption: {
      time: 'Uhrzeit',
      date: 'Datum',
      country: 'Land',
      importance: 'Wichtigkeit',
      importance_tip: 'Die Intensität des Ereignisses zeigt, wie stark es die Märkte, die Aktivität und das Verhalten der Trader beeinflusst',
      event: 'Ereignis',
      impacts: 'Auswirkungen',
      impacts_tip: 'Zeigt das Instrument, das aktiv auf das Ereignis reagiert',
      last: 'Letztes',
      last_tip: 'Die vorherigen Daten',
      forecast: 'Prognose',
      forecast_tip: 'Die Prognosedaten',
      fact: 'Tatsächlich',
      fact_tip: 'Die tatsächlichen Daten'
    },
    impact: {
      on: 'Dieser Bericht hat Auswirkungen auf',
      pips: '{pips} Pips',
      measured: 'Wir haben in den 4 Stunden nach den vergangenen Ereignissen eine Volatilität von {pips} gemessen.',
      goto_instrument: 'Zum {instrument} Instrument gehen',
      goto: 'Zu {instrument} gehen'
    },
    detailed: {
      back: 'Zurück zum Kalender',
      copy: 'URL kopieren',
      download_xls: 'XLS herunterladen',
      download_csv: 'CSV herunterladen',
      date: 'Datum',
      showing: '{0} von {1} Einträgen werden angezeigt',
      actual: 'Aktuell',
      forecast: 'Prognose'
    }
  },
  'fr-fr': {
    filters: {
      filters: 'Filtres',
      all_countries: 'Tous les pays',
      search: 'Chercher',
      all_importances: 'Tous les événements',
      importance: 'Importance',
      countries: 'Pays',
      dates: 'Fourchette des dates',
      date: 'Dates',
      gmt: 'GMT',
      all_from: 'Tous les éléments à venir du',
      all_till: 'Tous les éléments à venir jusqu\'au',
      from: 'À partir de',
      to: 'Jusqu\'en',
      clear: 'Effacer',
      load_more: 'Charger plus',
      cancel: 'Annuler',
      apply: 'Appliquer',
      no_data: 'Aucune donnée pour la période sélectionnée'
    },
    importance: {
      low: 'Plus bas',
      low_short: 'Bas',
      low_full: 'Faible importance',
      low_tip: 'Historiquement, un événement qui bouge moins sur le marché, sauf dans les circonstances les plus extraordinaires',
      medium: 'Moyenne',
      medium_short: 'Moy',
      medium_full: 'Importance moyenne',
      medium_tip: 'Il est relativement moins susceptible de forcer de grands mouvements du marché, sauf en cas de grandes surprises',
      high: 'Élevée',
      high_short: 'Haut',
      high_full: 'Importance élevée',
      high_tip: 'Qui ont historiquement causé la volatilité du marché'
    },
    countries: {
      US: 'États-Unis',
      AU: 'Australie',
      AT: 'Autriche',
      BE: 'Belgique',
      BR: 'Brésil',
      CA: 'Canada',
      CN: 'Chine',
      CY: 'Chypre',
      CZ: 'République tchèque',
      DK: 'Danemark',
      EA: 'Zone euro',
      FI: 'Finlande',
      FR: 'France',
      DE: 'Allemagne',
      GR: 'Grèce',
      HK: 'Hong Kong',
      HU: 'Hongrie',
      IN: 'Inde',
      ID: 'Indonésie',
      IE: 'Irlande',
      IT: 'Italie',
      JP: 'Japon',
      LU: 'Luxembourg',
      MX: 'Mexique',
      NL: 'Pays Bas',
      NZ: 'Nouvelle-Zélande',
      NO: 'Norvège',
      PL: 'Pologne',
      PT: 'Portugal',
      RO: 'Roumanie',
      RU: 'Russie',
      SG: 'Singapour',
      ZA: 'Afrique du Sud',
      KR: 'Corée du Sud',
      ES: 'Espagne',
      SE: 'Suède',
      CH: 'Suisse',
      TR: 'Turquie',
      GB: 'Royaume-Uni'
    },
    regions: {
      g7: 'G7',
      eu: 'Union européenne',
      la: 'Amérique latine',
      as: 'Asie',
      all: 'Tous'
    },
    dateShorthands: {
      today: 'Aujourd\'hui',
      tomorrow: 'Demain',
      twodays: '2 jours',
      week: 'Cette semaine',
      nextweek: 'Semaine prochaine',
      month: 'Ce mois-ci'
    },
    timezones: {
      'Pacific/Pago_Pago': 'Pacifique/Pago_Pago',
      'Pacific/Honolulu': 'Pacifique/Honolulu',
      'Pacific/Tahiti': 'Pacifique/Tahiti',
      'America/Anchorage': 'Amérique/Anchorage',
      'America/Los_Angeles': 'Amérique/Los_Angeles',
      'America/Denver': 'Amérique/Denver',
      'America/Chicago': 'Amérique/Chicago',
      'America/New_York': 'Amérique/New_York',
      'America/Halifax': 'Amérique/Halifax',
      'America/Argentina/Buenos_Aires': 'Amérique/Argentine/Buenos_Aires',
      'America/Sao_Paulo': 'Amérique/Sao_Paulo',
      'Atlantic/Azores': 'Atlantique/Açores',
      'Europe/London': 'Europe/Londres',
      'Europe/Berlin': 'Europe/Berlin',
      'Europe/Helsinki': 'Europe/Helsinki',
      'Europe/Istanbul': 'Europe/Moscou',
      'Asia/Dubai': 'Asie/Dubaï',
      'Asia/Kabul': 'Asie/Kaboul',
      'Indian/Maldives': 'Indien/Maldives',
      'Asia/Calcutta': 'Asie/Calcutta',
      'Asia/Kathmandu': 'Asie/Katmandou',
      'Asia/Dhaka': 'Asie/Dhaka',
      'Indian/Cocos': 'Indien/Îles_Cocos',
      'Asia/Bangkok': 'Asie/Bangkok',
      'Asia/Hong_Kong': 'Asie/Hong_Kong',
      'Asia/Pyongyang': 'Asie/Pyongyang',
      'Asia/Tokyo': 'Asie/Tokyo',
      'Australia/Darwin': 'Australie/Darwin',
      'Australia/Brisbane': 'Australie/Brisbane',
      'Australia/Adelaide': 'Australie/Adélaïde',
      'Australia/Sydney': 'Australie/Sydney',
      'Pacific/Nauru': 'Pacifique/Nauru',
      'Pacific/Auckland': 'Pacifique/Auckland',
      'Pacific/Kiritimati': 'Pacifique/Kiritimati'
    },
    caption: {
      time: 'Heure',
      date: 'Date',
      country: 'Pays',
      importance: 'Importance',
      importance_tip: 'L\'intensité de l\'événement, montre à quel point cet événement influe sur les marchés, l\'activité et les comportements des traders',
      event: 'Évènement',
      impacts: 'Impacts',
      impacts_tip: 'Affiche l\'instrument qui réagit activement à l\'événement',
      last: 'Dernier',
      last_tip: 'Les données précédentes',
      forecast: 'Prévision',
      forecast_tip: 'Les données de la prévision',
      fact: 'Fait',
      fact_tip: 'Les données des faits'
    },
    impact: {
      on: 'Ce rapport a un impact sur',
      pips: '{pips} pips',
      measured: 'Nous avons mesuré une volatilité de {pips} dans les 4 heures suivant les événements passés.',
      goto_instrument: 'Aller à l\'instrument {instrument}',
      goto: 'Aller à {instrument}'
    },
    detailed: {
      back: 'Retourner au calendrier',
      copy: 'Copier l\'URL',
      download_xls: 'Télécharger XLS',
      download_csv: 'Télécharger CSV',
      date: 'Date',
      showing: 'Affichage des entrées de {0} à {1}',
      actual: 'Actuel',
      forecast: 'Prévision'
    }
  },
  it: {
    filters: {
      filters: 'Filtri',
      all_countries: 'Tutte le nazioni',
      search: 'Ricerca',
      all_importances: 'Tutti gli eventi',
      importance: 'Importanza',
      countries: 'Nazioni',
      dates: 'Intervallo data',
      date: 'Date',
      gmt: 'GMT',
      all_from: 'Tutto in arrivo da',
      all_till: 'Tutto in arrivo fino a',
      from: 'Dal',
      to: 'Fino al',
      clear: 'Cancella',
      load_more: 'Carica di più',
      cancel: 'Annulla',
      apply: 'Applicare',
      no_data: 'Nessun dato per il periodo selezionato'
    },
    importance: {
      low: 'Basso',
      low_short: 'Bas',
      low_full: 'Bassa Importanza',
      low_tip: 'Evento storicamente meno movimentato dal mercato tranne nelle circostanze più straordinarie',
      medium: 'Medio',
      medium_short: 'Med',
      medium_full: 'Importanza Media',
      medium_tip: 'Relativamente meno probabile che crei importanti movimenti di mercato tranne che in caso di notizie inaspettate',
      high: 'Elevato',
      high_short: 'Alta',
      high_full: 'Importanza Elevata',
      high_tip: 'Che hanno storicamente aumentato la volatilità del mercato'
    },
    countries: {
      US: 'Stati Uniti',
      AU: 'Australia',
      AT: 'Austria',
      BE: 'Belgio',
      BR: 'Brasile',
      CA: 'Canada',
      CN: 'Cina',
      CY: 'Cipro',
      CZ: 'Repubblica Ceca',
      DK: 'Danimarca',
      EA: 'Area Euro',
      FI: 'Finlandia',
      FR: 'Francia',
      DE: 'Germania',
      GR: 'Grecia',
      HK: 'Hong Kong',
      HU: 'Ungheria',
      IN: 'India',
      ID: 'Indonesia',
      IE: 'Irlanda',
      IT: 'Italia',
      JP: 'Giappone',
      LU: 'Lussemburgo',
      MX: 'Messico',
      NL: 'Paesi Bassi',
      NZ: 'Nuova Zelanda',
      NO: 'Norvegia',
      PL: 'Polonia',
      PT: 'Portogallo',
      RO: 'Romania',
      RU: 'Russia',
      SG: 'Singapore',
      ZA: 'Sud Africa',
      KR: 'Corea Del Sud',
      ES: 'Spagna',
      SE: 'Svezia',
      CH: 'Svizzera',
      TR: 'Turchia',
      GB: 'Regno Unito'
    },
    regions: {
      g7: 'G7',
      eu: 'Unione Europea',
      la: 'America Latina',
      as: 'Asia',
      all: 'Tutto'
    },
    dateShorthands: {
      today: 'Oggi',
      tomorrow: 'Domani',
      twodays: '2 giorni',
      week: 'Questa settimana',
      nextweek: 'Prossima settimana',
      month: 'Questo mese'
    },
    timezones: {
      'Pacific/Pago_Pago': 'Pacifico/Pago_Pago',
      'Pacific/Honolulu': 'Pacifico/Honolulu',
      'Pacific/Tahiti': 'Pacifico/Tahiti',
      'America/Anchorage': 'America/Anchorage',
      'America/Los_Angeles': 'America/Los_Angeles',
      'America/Denver': 'America/Denver',
      'America/Chicago': 'America/Chicago',
      'America/New_York': 'America/New_York',
      'America/Halifax': 'America/Halifax',
      'America/Argentina/Buenos_Aires': 'America/Argentina/Buenos_Aires',
      'America/Sao_Paulo': 'America/Sao_Paulo',
      'Atlantic/Azores': 'Atlantico/Azores',
      'Europe/London': 'Europa/Londra',
      'Europe/Berlin': 'Europa/Berlino',
      'Europe/Helsinki': 'Europa/Helsinki',
      'Europe/Istanbul': 'Europa/Mosca',
      'Asia/Dubai': 'Asia/Dubai',
      'Asia/Kabul': 'Asia/Kabul',
      'Indian/Maldives': 'Indiano/Maldive',
      'Asia/Calcutta': 'Asia/Calcutta',
      'Asia/Kathmandu': 'Asia/Kathmandu',
      'Asia/Dhaka': 'Asia/Dhaka',
      'Indian/Cocos': 'Indiano/Cocos',
      'Asia/Bangkok': 'Asia/Bangkok',
      'Asia/Hong_Kong': 'Asia/Hong_Kong',
      'Asia/Pyongyang': 'Asia/Pyongyang',
      'Asia/Tokyo': 'Asia/Tokyo',
      'Australia/Darwin': 'Australia/Darwin',
      'Australia/Brisbane': 'Australia/Brisbane',
      'Australia/Adelaide': 'Australia/Adelaide',
      'Australia/Sydney': 'Australia/Sydney',
      'Pacific/Nauru': 'Pacifico/Nauru',
      'Pacific/Auckland': 'Pacifico/Auckland',
      'Pacific/Kiritimati': 'Pacifico/Kiritimati'
    },
    caption: {
      time: 'Ora',
      date: 'Data',
      country: 'Nazione',
      importance: 'Importanza',
      importance_tip: 'L\'intensità dell\'evento, mostra quanto sia forte l\'influenza di questo evento sui mercati, sulle attività e sui comportamenti dei trader',
      event: 'Evento',
      impacts: 'Impatti',
      impacts_tip: 'Mostra lo strumento che reagisce attivamente all\'evento',
      last: 'Ultimo',
      last_tip: 'I dati precedenti',
      forecast: 'Previsioni',
      forecast_tip: 'I dati delle previsioni',
      fact: 'Evento',
      fact_tip: 'I dati dell\'evento'
    },
    impact: {
      on: 'Questo report ha un impatto su',
      pips: '{pips} pip',
      measured: 'Abbiamo misurato {pips} la volatilità nelle 4 ore successive agli eventi passati.',
      goto_instrument: 'Vai allo strumento {instrument}',
      goto: 'Vai a {instrument}'
    },
    detailed: {
      back: 'Torna al calendario',
      copy: 'Copia URL',
      download_xls: 'Scarica XLS',
      download_csv: 'Scarica CSV',
      date: 'Data',
      showing: 'Visualizza da {0} a {1} voci',
      actual: 'Reale',
      forecast: 'Previsioni'
    }
  },
  es: {
    filters: {
      filters: 'Filtros',
      all_countries: 'Todos los países',
      search: 'Buscar',
      all_importances: 'Todos los eventos',
      importance: 'Importancia',
      countries: 'Países',
      dates: 'Rango de fecha',
      date: 'Fechas',
      gmt: 'GMT',
      all_from: 'Todos los siguientes a partir de',
      all_till: 'Todos los siguientes hasta',
      from: 'Desde',
      to: 'Hasta',
      clear: 'Borrar',
      load_more: 'Carga más',
      cancel: 'Cancelar',
      apply: 'Aplicar',
      no_data: 'Sin datos para el período seleccionado'
    },
    importance: {
      low: 'Baja',
      low_short: 'Baja',
      low_full: 'Importancia baja',
      low_tip: 'Históricamente un evento que mueve menos el mercado, excepto en las circunstancias más extraordinarias',
      medium: 'Media',
      medium_short: 'Med',
      medium_full: 'Importancia media',
      medium_tip: 'Es relativamente menos probable que obligue a realizar grandes movimientos en el mercado, excepto en el caso de sorpresas de gran envergadura',
      high: 'Alta',
      high_short: 'Alta',
      high_full: 'Importancia alta',
      high_tip: 'Que históricamente han provocado la volatilidad del mercado'
    },
    countries: {
      US: 'Estados Unidos',
      AU: 'Australia',
      AT: 'Austria',
      BE: 'Bélgica',
      BR: 'Brasil',
      CA: 'Canadá',
      CN: 'China',
      CY: 'Chipre',
      CZ: 'República Checa',
      DK: 'Dinamarca',
      EA: 'Eurozona',
      FI: 'Finlandia',
      FR: 'Francia',
      DE: 'Alemania',
      GR: 'Grecia',
      HK: 'Hong Kong',
      HU: 'Hungría',
      IN: 'India',
      ID: 'Indonesia',
      IE: 'Irlanda',
      IT: 'Italia',
      JP: 'Japón',
      LU: 'Luxemburgo',
      MX: 'México',
      NL: 'Países Bajos',
      NZ: 'Nueva Zelanda',
      NO: 'Noruega',
      PL: 'Polonia',
      PT: 'Portugal',
      RO: 'Rumanía',
      RU: 'Rusia',
      SG: 'Singapur',
      ZA: 'Sudáfrica',
      KR: 'Corea del Sur',
      ES: 'España',
      SE: 'Suecia',
      CH: 'Suiza',
      TR: 'Turquía',
      GB: 'Reino Unido'
    },
    regions: {
      g7: 'G7',
      eu: 'Unión Europea',
      la: 'América Latina',
      as: 'Asia',
      all: 'Todos'
    },
    dateShorthands: {
      today: 'Hoy',
      tomorrow: 'Mañana',
      twodays: '2 días',
      week: 'Esta semana',
      nextweek: 'Próxima semana',
      month: 'Este mes'
    },
    timezones: {
      'Pacific/Pago_Pago': 'Pacífico/Pago_Pago',
      'Pacific/Honolulu': 'Pacífico/Honolulu',
      'Pacific/Tahiti': 'Pacífico/Tahití',
      'America/Anchorage': 'América/Anchorage',
      'America/Los_Angeles': 'América/Los_Ángeles',
      'America/Denver': 'América/Denver',
      'America/Chicago': 'América/Chicago',
      'America/New_York': 'América/Nueva_York',
      'America/Halifax': 'América/Halifax',
      'America/Argentina/Buenos_Aires': 'América/Argentina/Buenos_Aires',
      'America/Sao_Paulo': 'América/São Paulo',
      'Atlantic/Azores': 'Atlántico/Azores',
      'Europe/London': 'Europa/Londres',
      'Europe/Berlin': 'Europa/Berlín',
      'Europe/Helsinki': 'Europa/Helsinki',
      'Europe/Istanbul': 'Europa/Moscú',
      'Asia/Dubai': 'Asia/Dubai',
      'Asia/Kabul': 'Asia/Kabul',
      'Indian/Maldives': 'Índico/Maldivas',
      'Asia/Calcutta': 'Asia/Calcuta',
      'Asia/Kathmandu': 'Asia/Katmandú',
      'Asia/Dhaka': 'Asia/Daca',
      'Indian/Cocos': 'Índico/Islas_Cocos',
      'Asia/Bangkok': 'Asia/Bangkok',
      'Asia/Hong_Kong': 'Asia/Hong_Kong',
      'Asia/Pyongyang': 'Asia/Pionyang',
      'Asia/Tokyo': 'Asia/Tokio',
      'Australia/Darwin': 'Australia/Darwin',
      'Australia/Brisbane': 'Australia/Brisbane',
      'Australia/Adelaide': 'Australia/Adelaida',
      'Australia/Sydney': 'Australia/Sídney',
      'Pacific/Nauru': 'Pacífico/Nauru',
      'Pacific/Auckland': 'Pacífico/Auckland',
      'Pacific/Kiritimati': 'Pacífico/Kiritimati'
    },
    caption: {
      time: 'Hora',
      date: 'Fecha',
      country: 'País',
      importance: 'Importancia',
      importance_tip: 'La intensidad del evento muestra la fuerza de este evento que influye en los mercados, la actividad y el comportamiento de los traders',
      event: 'Evento',
      impacts: 'Impactos',
      impacts_tip: 'Muestra el instrumento que reacciona activamente por el evento',
      last: 'Última',
      last_tip: 'Los datos anteriores',
      forecast: 'Pronóstico',
      forecast_tip: 'Los datos del pronóstico',
      fact: 'Hecho',
      fact_tip: 'Datos de los hechos'
    },
    impact: {
      on: 'Este informe tiene un impacto en',
      pips: '{pips} pips',
      measured: 'Medimos una volatilidad de {pips} en las 4 horas posteriores a los eventos pasados.',
      goto_instrument: 'Ir al instrumento {instrument}',
      goto: 'Ir a {instrument}'
    },
    detailed: {
      back: 'Volver al calendario',
      copy: 'Copiar URL',
      download_xls: 'Descargar XLS',
      download_csv: 'Descargar CSV',
      date: 'Fecha',
      showing: 'Mostrando entradas de {0} a {1}',
      actual: 'Real',
      forecast: 'Pronóstico'
    }
  },
  'zh-hans': {
    filters: {
      filters: '筛选器',
      all_countries: '所有国家',
      search: '搜索',
      all_importances: '所有事件',
      importance: '重要性',
      countries: '国家',
      dates: '日期范围',
      date: '日',
      gmt: '格林威治标准时间',
      all_from: '起所有事件',
      all_till: '前所有事件',
      from: '从',
      to: '至',
      clear: '清除',
      load_more: '装载更多',
      cancel: '取消',
      apply: '应用',
      no_data: '所选期间无数据'
    },
    importance: {
      low: '低',
      low_short: '低',
      low_full: '低重要性',
      low_tip: '过去对市场波动较小的事件（除特殊情况）',
      medium: '中',
      medium_short: '中',
      medium_full: '中等重要性',
      medium_tip: '引起市场重大波动的可能性相对较小（除特殊情况）',
      high: '高',
      high_short: '高',
      high_full: '高重要性',
      high_tip: '过去曾引发市场波动'
    },
    countries: {
      US: '美国',
      AU: '澳大利亚',
      AT: '奥地利',
      BE: '比利时',
      BR: '巴西',
      CA: '加拿大',
      CN: '中国',
      CY: '塞浦路斯',
      CZ: '捷克',
      DK: '丹麦',
      EA: '欧元区',
      FI: '芬兰',
      FR: '法国',
      DE: '德国',
      GR: '希腊',
      HK: '香港地区',
      HU: '匈牙利',
      IN: '印度',
      ID: '印度尼西亚',
      IE: '爱尔兰',
      IT: '意大利',
      JP: '日本',
      LU: '卢森堡',
      MX: '墨西哥',
      NL: '荷兰',
      NZ: '新西兰',
      NO: '挪威',
      PL: '波兰',
      PT: '葡萄牙',
      RO: '罗马尼亚',
      RU: '俄罗斯',
      SG: '新加坡',
      ZA: '南非',
      KR: '韩国',
      ES: '西班牙',
      SE: '瑞典',
      CH: '瑞士',
      TR: '土耳其',
      GB: '英国'
    },
    regions: {
      g7: 'G7（七国集团）',
      eu: '欧盟',
      la: '拉丁美洲',
      as: '亚洲',
      all: '所有'
    },
    dateShorthands: {
      today: '今天',
      tomorrow: '明天',
      twodays: '2 天',
      week: '本周',
      nextweek: '下周',
      month: '本月'
    },
    timezones: {
      'Pacific/Pago_Pago': '太平洋地区/帕果帕果',
      'Pacific/Honolulu': '太平洋地区/檀香山',
      'Pacific/Tahiti': '太平洋地区/大溪地',
      'America/Anchorage': '美洲/安克雷奇',
      'America/Los_Angeles': '美洲/洛杉矶',
      'America/Denver': '美洲/丹佛',
      'America/Chicago': '美洲/芝加哥',
      'America/New_York': '美洲/纽约',
      'America/Halifax': '美洲/哈利法克斯',
      'America/Argentina/Buenos_Aires': '美洲/阿根廷/布宜诺斯艾利斯',
      'America/Sao_Paulo': '美洲/圣保罗',
      'Atlantic/Azores': '大西洋地区/亚速尔群岛',
      'Europe/London': '欧洲/伦敦',
      'Europe/Berlin': '欧洲/柏林',
      'Europe/Helsinki': '欧洲/赫尔辛基',
      'Europe/Istanbul': '欧洲/莫斯科',
      'Asia/Dubai': '亚洲/迪拜',
      'Asia/Kabul': '亚洲/喀布尔',
      'Indian/Maldives': '印度/达尔代夫',
      'Asia/Calcutta': '亚洲/加尔各答',
      'Asia/Kathmandu': '亚洲/加德满都',
      'Asia/Dhaka': '亚洲/达卡',
      'Indian/Cocos': '印度/科科群岛',
      'Asia/Bangkok': '亚洲/曼谷',
      'Asia/Hong_Kong': '亚洲/香港地区',
      'Asia/Pyongyang': '亚洲/平壤',
      'Asia/Tokyo': '亚洲/东京',
      'Australia/Darwin': '澳大利亚/达尔文',
      'Australia/Brisbane': '澳大利亚/布里斯本',
      'Australia/Adelaide': '澳大利亚/阿德莱德',
      'Australia/Sydney': '澳大利亚/悉尼',
      'Pacific/Nauru': '太平洋地区/瑙鲁',
      'Pacific/Auckland': '太平洋地区/奥克兰',
      'Pacific/Kiritimati': '太平洋地区/圣诞岛'
    },
    caption: {
      time: '时间',
      date: '日期',
      country: '国家',
      importance: '重要性',
      importance_tip: '事件强烈程度显示了该事件对市场、交易活动及交易商行为的影响程度',
      event: '事件',
      impacts: '影响',
      impacts_tip: '显示受此事件影响较大的交易工具',
      last: '最近',
      last_tip: '此前数据',
      forecast: '预测',
      forecast_tip: '预测数据',
      fact: '实际',
      fact_tip: '实际数据'
    },
    impact: {
      on: '此报告影响了',
      pips: '{pips} 点',
      measured: '我们测量了{pips} 过去事件发生后 4 小时内的波动性。',
      goto_instrument: '前往 {instrument} 交易工具',
      goto: '前往 {instrument}'
    },
    detailed: {
      back: '回到日历',
      copy: '复制 URL',
      download_xls: '下载 XLS',
      download_csv: '下载 CSV',
      date: '日期',
      showing: '显示 {0} 至 {1} 条目',
      actual: '实际',
      forecast: '预测'
    }
  },
  ru: {
    filters: {
      filters: 'Фильтры',
      all_countries: 'Все страны',
      search: 'Поиск',
      all_importances: 'Все события',
      importance: 'Важность',
      countries: 'Страны',
      dates: 'Период',
      date: 'Даты',
      gmt: 'GMT',
      all_from: 'Все предстоящие начиная с',
      all_till: 'Все предстоящие до',
      from: 'Начиная с',
      to: 'По',
      clear: 'Очистить',
      load_more: 'Загрузить ещё',
      cancel: 'Отмена',
      apply: 'Применить',
      no_data: 'Нет данных за выбранный период'
    },
    importance: {
      low: 'Низкая',
      low_short: 'Низк',
      low_full: 'Низкая важность',
      low_tip: 'Событие исторически имеющее меньшее влияние на движеня рынка, за исключением самых чрезывчайных ситуаций',
      medium: 'Средняя',
      medium_short: 'Сред',
      medium_full: 'Средняя важность',
      medium_tip: 'С относительно невысокой вероятностью вызвать значительные дивижения на рынке, за исключением чрезвычайных событий',
      high: 'Высокая',
      high_short: 'Выс',
      high_full: 'Высокая важность',
      high_tip: 'Которые исторически вызывали волатильность рынка'
    },
    countries: {
      US: 'Соединенные Штаты Америки',
      AU: 'Австралия',
      AT: 'Австрия',
      BE: 'Бельгия',
      BR: 'Бразилия',
      CA: 'Канада',
      CN: 'Китай',
      CY: 'Кипр',
      CZ: 'Чешская Республика',
      DK: 'Дания',
      EA: 'Евро зона',
      FI: 'Финляндия',
      FR: 'Франция',
      DE: 'Германия',
      GR: 'Греция',
      HK: 'Гонконг',
      HU: 'Венгрия',
      IN: 'Индия',
      ID: 'Индонезия',
      IE: 'Ирландия',
      IT: 'Италия',
      JP: 'Япония',
      LU: 'Люксембург',
      MX: 'Мексика',
      NL: 'Нидерланды',
      NZ: 'Новая Зеландия',
      NO: 'Норвегия',
      PL: 'Польша',
      PT: 'Португалия',
      RO: 'Румыния',
      RU: 'Россия',
      SG: 'Сингапур',
      ZA: 'Южная Африка',
      KR: 'Южная Корея',
      ES: 'Испания',
      SE: 'Швеция',
      CH: 'Швейцария',
      TR: 'Турция',
      GB: 'Соединенное Королевство'
    },
    regions: {
      g7: 'Большая семерка',
      eu: 'Европейский союз',
      la: 'Латинская Америка',
      as: 'Азия',
      all: 'Все'
    },
    dateShorthands: {
      today: 'Сегодня',
      tomorrow: 'Завтра',
      twodays: '2 дня',
      week: 'Эта неделя',
      nextweek: 'Следующая неделя',
      month: 'Этот месяц'
    },
    timezones: {
      'Pacific/Pago_Pago': 'Тихоокеанский регион/Паго_Паго',
      'Pacific/Honolulu': 'Тихоокеанский регион/Гонолулу',
      'Pacific/Tahiti': 'Тихоокеанский регион/Таити',
      'America/Anchorage': 'Америка/Анкоридж',
      'America/Los_Angeles': 'Америка/Лос-Анджелес',
      'America/Denver': 'Америка/Денвер',
      'America/Chicago': 'Америка/Чикаго',
      'America/New_York': 'Америка/Нью-Йорк',
      'America/Halifax': 'Америка/Халифакс',
      'America/Argentina/Buenos_Aires': 'Америка/Аргентина/Буэнос_Айрес',
      'America/Sao_Paulo': 'Америка/Сан_Паулу',
      'Atlantic/Azores': 'Атлантика/Азорские_острова',
      'Europe/London': 'Европа/Лондон',
      'Europe/Berlin': 'Европа/Берлин',
      'Europe/Helsinki': 'Европа/Хельсинки',
      'Europe/Istanbul': 'Европа/Москва',
      'Asia/Dubai': 'Азия/Дубай',
      'Asia/Kabul': 'Азия/Кабул',
      'Indian/Maldives': 'Индия/Мальдивы',
      'Asia/Calcutta': 'Азия/Калькутта',
      'Asia/Kathmandu': 'Азия/Катманду',
      'Asia/Dhaka': 'Азия/Дакка',
      'Indian/Cocos': 'Индия/Кокос',
      'Asia/Bangkok': 'Азия/Бангкок',
      'Asia/Hong_Kong': 'Азия/Гонконг',
      'Asia/Pyongyang': 'Азия/Пхеньян',
      'Asia/Tokyo': 'Азия/Токио',
      'Australia/Darwin': 'Австралия/Дарвин',
      'Australia/Brisbane': 'Австралия/Брисбен',
      'Australia/Adelaide': 'Австралия/Аделаида',
      'Australia/Sydney': 'Австралия/Сидней',
      'Pacific/Nauru': 'Тихоокеанский регион/Науру',
      'Pacific/Auckland': 'Тихоокеанский регион/Окленд',
      'Pacific/Kiritimati': 'Тихоокеанский регион/Киритимати'
    },
    caption: {
      time: 'Время',
      date: 'Дата',
      country: 'Страна',
      importance: 'Важность',
      importance_tip: 'Интенсивность события, показывает, насколько сильно его влияние на рынки, активность и поведение трейдеров',
      event: 'Событие',
      impacts: 'Влияние',
      impacts_tip: 'Показывает инструмент, который активно реагирует на событие',
      last: 'Последние',
      last_tip: 'Предыдущие данные',
      forecast: 'Прогноз',
      forecast_tip: 'Прогнозируемые данные',
      fact: 'Факт',
      fact_tip: 'Фактические данные'
    },
    impact: {
      on: 'Настоящий доклад имеет влияние на',
      pips: '{pips} пунктов',
      measured: 'За последние 4 часа после события нами была отмечена волатильность {pips}.',
      goto_instrument: 'Перейти к инструменту {instrument}',
      goto: 'Перейти к {instrument}'
    },
    detailed: {
      back: 'Вернуться в календарь',
      copy: 'Копировать URL',
      download_xls: 'Скачать XLS',
      download_csv: 'Скачать CSV',
      date: 'Дата',
      showing: 'Показаны {0} из {1} записей',
      actual: 'Актуальные',
      forecast: 'Прогноз'
    }
  }
}
