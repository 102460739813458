<template>
  <div class="list">
    <ListCaption />

    <div v-for="day in events" :key="`${day[0].timestamp}-date-row`">
      <DateCaption :date="day[0].timestamp" />

      <Row
        v-for="(event, i) in day" :key="event.id"
        :event="event"
        :sameOptions="sameOptions(day[i-1], event)" />
    </div>
  </div>
</template>

<script>
import ListCaption from '@/components/ListCaption.vue'
import DateCaption from '@/components/DateCaption.vue'
import Row from '@/components/Row.vue'
import utils from '@/utils'

export default {
  name: 'List',

  components: {
    ListCaption,
    DateCaption,
    Row
  },

  computed: {
    events () {
      return utils.sortInDays(this.$store.state.events)
    }
  },

  methods: {
    sameOptions (prev, curr) {
      const sameTime = prev === undefined ? false : prev.timestamp.substring(0, 16) === curr.timestamp.substring(0, 16)
      const sameCountry = prev === undefined ? false : prev.region === curr.region

      return {
        sameTime: sameTime,
        sameCountry: sameTime && sameCountry
      }
    }
  }
}
</script>
