<template>
  <div class="economcalendar-header">
    <div class="economcalendar-header__btn economcalendar-header__btn--left">
      <slot name="left"></slot>
    </div>
    <div class="economcalendar-header__title">
      <slot></slot>
    </div>
    <div class="economcalendar-header__btn economcalendar-header__btn--right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped>
.economcalendar-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e0e2e4;
}

.economcalendar-header__title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  color: #111;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.economcalendar-header__btn--left {margin-right: auto;}
.economcalendar-header__btn--right {margin-left: auto;}
</style>
