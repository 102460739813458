<template>
  <div class="importance-list">
    <div class="importance-list__item"
      v-for="importance in $config.importance"
      :class="{'importance-list__item--selected': isActive(importance)}"
      :key="importance"
      @click="updateImportance(importance)">
      <div class="importance-list__name"
        :class="'importance-list__name--' + importance">{{ $t(`importance.${importance}`) }}</div>
      <div class="importance-list__description">{{ $t(`importance.${importance}_tip`) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterImportance',

  computed: {
    selectedImportanceList () {
      return this.$store.state.proxy.importance
    }
  },

  methods: {
    updateImportance (level) {
      let importances2update

      if (this.selectedImportanceList.includes(level)) {
        importances2update = this.selectedImportanceList.filter(el => el !== level)
      } else {
        importances2update = this.selectedImportanceList.concat(level)
      }

      this.$store.commit('setProxyImportance', { importance: importances2update })
      this.$emit('filterWasUpdated')
    },

    isActive (level) {
      return this.selectedImportanceList.indexOf(level) !== -1
    }
  }
}
</script>

<style scoped>
.importance-list {}

.importance-list__item {
  position: relative;
  width: 100%;
  margin-bottom: 8px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}
.importance-list__item:last-child {margin-bottom: 0;}

.importance-list__item::before {
  content: '';
  position: absolute;
  top: 6px;
  right: 0;
  width: 10px;
  height: 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
}
.importance-list__item--selected::after {
  content: '';
  position: absolute;
  top: 3px;
  right: -1px;
  width: 12px;
  height: 7px;
  border-left: 2px solid #e8582f;
  border-bottom: 2px solid #e8582f;
  transform: rotate(-45deg);
}

.importance-list__name {
  font-size: 16px;
  line-height: 24px;
  user-select: none;
}
.importance-list__item--selected .importance-list__name {font-weight: 500;}

.importance-list__name::before {
  content: "";
  position: absolute;
  left: -12px;
  top: 10px;
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 3px;
}
.importance-list__name--high::before {background-color: #FF3300;}
.importance-list__name--medium::before {background-color: #FFD600;}
.importance-list__name--low::before {background-color: #53A642;}

.importance-list__description {
  color: #909294;
  font-size: 12px;
  line-height: 16px;
}
</style>
