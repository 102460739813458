<template>
  <div class="caption"
    :style="styleObject">
    <div class="cell__time">
      {{ $t('caption.time') }}
    </div>
    <div class="cell__country">
      {{ $t('caption.country') }}
    </div>
    <div class="cell__importance">
      {{ $t('caption.importance') }}
      <InfoTip>{{ $t('caption.importance_tip') }}</InfoTip>
    </div>
    <div class="cell__event">
      {{ $t('caption.event') }}
    </div>
    <div class="cell__impact">
      {{ $t('caption.impacts') }}
      <InfoTip>{{ $t('caption.impacts_tip') }}</InfoTip>
    </div>
    <div class="cell__last">
      {{ $t('caption.last') }}
      <InfoTip>{{ $t('caption.last_tip') }}</InfoTip>
    </div>
    <div class="cell__forecast">
      {{ $t('caption.forecast') }}
      <InfoTip>{{ $t('caption.forecast_tip') }}</InfoTip>
    </div>
    <div class="cell__fact">
      {{ $t('caption.fact') }}
      <InfoTip>{{ $t('caption.fact_tip') }}</InfoTip>
    </div>
    <div class="cell__expand" v-if="!isDetailedView"></div>
  </div>
</template>

<script>
import InfoTip from '@/components/InfoTip.vue'
import '@/assets/sizes.css'

export default {
  name: 'ListCaption',

  components: {
    InfoTip
  },

  props: {
    isDetailedView: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    headerOffset () {
      return this.$store.state.headerOffset
    },

    styleObject () {
      const styles = {
        position: 'sticky',
        zIndex: 2,
        top: `${this.headerOffset + 62}px`
      }

      return this.isDetailedView ? {} : styles
    }
  }
}
</script>

<style scoped>
.caption {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 36px;
  box-sizing: content-box;
  margin-bottom: -1px;
  border-top: 1px solid #E0E2E4;
  border-bottom: 1px solid #E0E2E4;
  background-color: #fff;
  font-size: 12px;
  line-height: 16px;
}

.cell__time,
.cell__country,
.cell__importance,
.cell__event,
.cell__impact,
.cell__last,
.cell__forecast,
.cell__fact {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
</style>
