import Vue from 'vue'
import VueI18n from 'vue-i18n'
import EconomCalendar from './EconomCalendar.vue'
import router from './router'
import store from './store'
import translations from '@/i18n'
import utils from '@/utils'
import config from '@/config'
import vClickOutside from 'v-click-outside'
import 'promise-polyfill/src/polyfill'

Vue.config.productionTip = false

// Get config parameters from build script
const envs = process.env
config.default = JSON.parse(envs.VUE_APP_DEFAULTS)
// Test cases
if (envs.VUE_APP_SOURCE !== undefined) {
  config.default.src = envs.VUE_APP_SOURCE
}
Vue.prototype.$config = config

Vue.use(vClickOutside)
Vue.use(VueI18n)

Vue.directive('throttled-on', { // Let's create a throttle directive
  bind (el, binding) {
    const type = binding.arg
    const handler = utils.throttle(binding.value, 50)

    el.addEventListener(type, handler)
  }
})

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: translations
})

/* eslint-disable no-new */
new Vue({
  el: '#econom-calendar',
  router,
  store,
  i18n,
  render (h) {
    return h(EconomCalendar, {
      props: {
        lang: this.$el.attributes.lang ? this.$el.attributes.lang.value : config.default.lang,
        headerOffset: this.$el.attributes['data-header-offset'] ? this.$el.attributes['data-header-offset'].value : '0'
      }
    })
  }
})
/* eslint-enable no-new */
