<template>
  <div v-if="!loaded" class="history-loading"></div>

  <div class="chart-block" v-else-if="history.length > 0">
    <div class="chart-container">
      <svg class="chart" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg"
        v-if="visible.length > 0"
        :height="CHART_HEIGHT"
        :viewBox="`0 0 736 ${CHART_HEIGHT}`">
        <!-- x axis -->
        <path :d="`M 0 ${xAxis} L 736 ${xAxis}`" stroke="#CFD1D5" stroke-width="0.5"></path>
        <!-- every year line -->
        <path stroke-width="0.5" stroke="#CFD1D5"
          v-for="year in filterYearLines(chartDates)" :key="`chart-year-${year.timestamp}`"
          :d="`M ${year.x4chart} 0 L ${year.x4chart} ${CHART_HEIGHT}`"></path>
        <!-- every day result here -->
        <g class="chart-columns"
          v-for="(item, i) in visible" :key="`chart-item-${i}${item.timestamp}`"
          :transform="`translate(${i * chart.step},0)`"
          :data-actual="item.actual"
          :data-forecast="item.forecast"
          :data-timestamp="item.timestamp"
          @pointerenter="showTooltip"
          @pointerleave="hideTooltip()">
          <path fill="#1192E8" class="chart-column--actual"
            :transform="`translate(${chart.step * 0.19},0)`"
            :d="drawRect(item.actual)"></path>
          <path fill="#2CA3A2" class="chart-column--forecast"
            :transform="`translate(${chart.step * 0.53},0)`"
            :d="drawRect(item.forecast)"></path>
        </g>
      </svg>
      <svg class="scale" width="64" xmlns="http://www.w3.org/2000/svg"
        v-if="scale.length > 0"
        :height="CHART_HEIGHT">
        <!-- y axis -->
        <path :d="`M 0.5 0 L 0.5 ${CHART_HEIGHT}`" stroke="#CFD1D5"></path>
        <!-- zero label dash and label -->
        <path :d="`M 0 ${xAxis} L 8 ${xAxis}`" stroke="#CFD1D5" stroke-width="0.5"></path>
        <!-- every label dash -->
        <path stroke="#CFD1D5" stroke-width="0.5"
          v-for="dash in scale" :key="`scale-dash-${dash.y}`"
          :d="`M 0 ${dash.y} L 8 ${dash.y}`"></path>
        <!-- every label digit -->
        <text x="16" class="label label--vertical"
          v-for="label in scale" :key="`scale=label-${label.y}`"
          :y="label.y">{{ label.label }}</text>
      </svg>

      <div class="chart-tooltip"
        v-show="tooltip.isShown"
        :style="`top:${tooltip.y}px;left:${tooltip.x}px;transform:translateX(-${tooltip.shift}%)`">
        <b>{{ tooltip.date }}</b><br>
        {{ $t('detailed.actual') }}: <span>{{tooltip.actual}}</span><br>
        {{ $t('detailed.forecast') }}: <span>{{tooltip.forecast}}</span>
      </div>
    </div>

    <div class="chart-legend">
      <svg width="100%" height="16" class="chart-labels" xmlns="http://www.w3.org/2000/svg"
        v-if="chartDates.length > 0">
        <!-- every year label -->
        <text class="label label--horizontal"
          v-for="year in chartDates.filter((el, i) => i % legendDivisor === 0)" :key="`legend-${year.timestamp}`"
          :transform="`translate(${chart.step * width / (736 * 2)}, 0)`"
          :x="`${year.x4chart * 100 / 736}%`" y="12">
          {{ year.label }}
        </text>
      </svg>
    </div>

    <div class="minimap">
      <svg class="minimap-chart" fill="white" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg"
        ref="minimap"
        v-if="history.length > 0"
        :height="MINIMAP_HEIGHT"
        :viewBox="`0 0 736 ${MINIMAP_HEIGHT}`">
        <!-- every year line -->
        <path stroke-width="0.5" stroke="#CFD1D5"
          v-for="year in yearLines" :key="`minimap-years-${year.timestamp}`"
          :d="`M ${year.x4minimap} 0 L ${year.x4minimap} ${MINIMAP_HEIGHT}`"></path>
        <!-- forecast line -->
        <path :d="getCurve('forecast')" fill="url(#forecast_colors)" stroke="#2CA3A2" stroke-width="2" clip-path="url(#clip)"></path>
        <!-- actual line -->
        <path :d="getCurve('actual')" fill="url(#actual_colors)" stroke="#1192E8" stroke-width="2" clip-path="url(#clip)"></path>
        <!-- minimap border -->
        <path d="M0 0H736V32H0V0Z" fill="none" stroke="#E0E2E4" stroke-width="2" clip-path="url(#clip)"></path>

        <defs>
          <linearGradient id="forecast_colors" x1="368" y1="4" x2="368" y2="34" gradientUnits="userSpaceOnUse">
            <stop stop-color="#2CA3A2" stop-opacity="0.35"></stop>
            <stop offset="1" stop-color="#2CA3A2" stop-opacity="0"></stop>
          </linearGradient>
          <linearGradient id="actual_colors" x1="368" y1="4" x2="368" y2="34" gradientUnits="userSpaceOnUse">
            <stop stop-color="#1192E8" stop-opacity="0.34"></stop>
            <stop offset="1" stop-color="#1192E8" stop-opacity="0"></stop>
          </linearGradient>
          <clipPath id="clip">
            <rect width="736" :height="MINIMAP_HEIGHT" fill="white"></rect>
          </clipPath>
        </defs>
      </svg>

      <svg class="minimap-slider" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg"
        v-if="history.length > 0"
        :height="MINIMAP_HEIGHT"
        :viewBox="`0 0 100 ${MINIMAP_HEIGHT}`"
        @mouseup="captureOff"
        @touchend="captureOff"
        @mouseleave="captureOff"
        @touchcancel="captureOff"
        v-throttled-on:mousemove="move"
        v-throttled-on:touchmove="move">
        <path fill="rgba(17,146,232,0.3)" class="zoom-area" id="zoom-area"
          :d="`M ${slider.left} 0 H ${slider.right} V ${MINIMAP_HEIGHT} H ${slider.left} Z`"
          @mousedown="captureOn"
          @touchstart="captureOn"></path>
        <path fill="transparent" class="zoom-border" id="zoom-border-left"
          :d="`M ${slider.left - 1} 0 H ${slider.left + 1} V ${MINIMAP_HEIGHT} H ${slider.left - 1} Z`"
          @mousedown="captureOn"
          @touchstart="captureOn"></path>
        <path fill="transparent" class="zoom-border" id="zoom-border-right"
          :d="`M ${slider.right - 1} 0 H ${slider.right + 1} V ${MINIMAP_HEIGHT} H ${slider.right - 1} Z`"
          @mousedown="captureOn"
          @touchstart="captureOn"></path>
      </svg>

      <svg height="16" class="minimap-labels" xmlns="http://www.w3.org/2000/svg"
        v-if="yearLines.length >= 0">
        <!-- every year label -->
        <text class="label"
          v-for="year in yearLines" :key="`year-labels-${year.timestamp}`"
          :x="`${year.x4minimap * 100 / 736}%`" y="12">
          {{ year.year }}
        </text>
      </svg>

      <div>
        <i class="legend actual"></i>{{ $t('detailed.actual') }}
        <i class="legend forecast"></i>{{ $t('detailed.forecast') }}
      </div>
    </div>
  </div>

  <div v-else>
    {{ $t('filters.no_data') }}
  </div>
</template>

<script>
import utils from '@/utils'

export default {
  name: 'HistoryChart',

  props: {
    history: {
      type: Array,
      required: true
    },

    loaded: {
      type: Boolean,
      default: false
    },

    CHART_HEIGHT: {
      type: Number,
      default: 270
    },

    MINIMAP_HEIGHT: {
      type: Number,
      default: 32
    },

    PRESICION: { // vertical padding for data on the chart and minimap
      type: Number,
      default: 0.15
    }
  },

  computed: {
    visible () {
      return this.history.slice(this.visibleStart, this.visibleEnd)
    },

    xAxis () {
      return this.rate2coordinate(0, this.chart)
    },

    itemsPerMonth () {
      let repeats = 0

      for (let i = 1; i < this.history.length; i++) {
        if (this.history[i].timestamp.slice(5, 7) === this.history[i - 1].timestamp.slice(5, 7)) {
          repeats++
        }
      }

      return this.history.length / (this.history.length - repeats)
    },

    minimap () {
      return {
        min: this.arrExtr(this.history).min,
        max: this.arrExtr(this.history).max,
        height: this.MINIMAP_HEIGHT,
        count: this.history.length,
        step: 736 / (this.history.length - 1) // -1 is here because minimap is built from edge to edge precisely
      }
    },

    chart () {
      return {
        min: this.arrExtr(this.visible).chartMin,
        max: this.arrExtr(this.visible).chartMax,
        height: this.CHART_HEIGHT,
        count: this.visible.length,
        step: 736 / this.visible.length
      }
    },

    yearLines () {
      const result = this.history.map((el, i) => {
        el.x4minimap = i * this.minimap.step
        el.year = el.timestamp.slice(0, 4)

        return el
      })

      return this.filterYearLines(result)
    },

    chartDates () {
      const result = this.visible.map((el, i, arr) => {
        el.x4chart = i * this.chart.step
        el.year = el.timestamp.slice(0, 4)
        el.month = new Date(el.timestamp).toLocaleDateString(this.$store.state.language, { month: 'short' })
        el.date = new Date(el.timestamp).toLocaleDateString(this.$store.state.language, { month: 'short', day: 'numeric' })

        if ((i > 0 && el.year !== arr[i - 1].year) || (i === 0 && el.timestamp.slice(5, 7) === '01')) {
          // if the year is changed or it's the very first item of the whole history
          el.label = el.year
        } else if (this.needDatesInCalendar) {
          el.label = el.date
        } else {
          el.label = el.month
        }

        return el
      })

      return result
    },

    scale () {
      const scale = utils.generateScale(this.chart.min, this.chart.max)

      return scale.map(el => ({ y: this.rate2coordinate(el, this.chart), label: utils.formatNumber(el) }))
    }
  },

  data () {
    return {
      width: 736,
      slider: {
        left: 0,
        right: 100
      },
      visibleStart: 0,
      visibleEnd: this.history.length,
      tooltip: {
        isShown: false,
        x: 0,
        y: 0,
        date: '',
        shift: 0,
        actual: 0,
        forecast: 0
      },
      legendDivisor: 1,
      needDatesInCalendar: false
    }
  },

  methods: {
    allValues (array) {
      const actuals = array.map(el => Number(el.actual))
      const forecasts = array.map(el => Number(el.forecast))

      return actuals.concat(forecasts)
    },

    arrExtr (array) {
      const min = Math.min(...this.allValues(array))
      const max = Math.max(...this.allValues(array))
      const diff = max - min

      return {
        min: min - diff * this.PRESICION,
        max: max + diff * this.PRESICION,
        // for chart, if min is greater than zero, we'll show zero line with small space below
        chartMin: min >= 0 ? 0 - diff * this.PRESICION : min - diff * this.PRESICION,
        // same for max, but reverted
        chartMax: max <= 0 ? 0 + diff * this.PRESICION : max + diff * this.PRESICION
      }
    },

    formatDate (timestamp) {
      return new Date(timestamp).toLocaleDateString(this.locale, { month: 'short', day: 'numeric', year: 'numeric' })
    },

    filterYearLines (array) { // returns only year changed occurances or the first January month
      const result = array.filter((el, i, arr) => {
        return (i === 0 && el.timestamp.slice(5, 7) === '01') || (i > 0 && el.timestamp.slice(0, 4) !== arr[i - 1].timestamp.slice(0, 4))
      })

      return result
    },

    rate2coordinate (rate, chart) {
      const invertedCoordinate = (rate - chart.min) * chart.height / (chart.max - chart.min)

      return chart.height - invertedCoordinate
    },

    getCurve (type) {
      const path = this.history.map((day, i) => `L ${i * this.minimap.step},${this.rate2coordinate(Number(day[type]), this.minimap)}`).join(' ')

      return `M -1 ${this.MINIMAP_HEIGHT + 2} ${path} L 737 ${this.MINIMAP_HEIGHT + 2}`
    },

    drawRect (value) {
      return `M 0 ${this.xAxis} H ${this.chart.step * 0.28} V ${this.rate2coordinate(value, this.chart)} H 0 Z`
    },

    calculateMonthes (array) {
      const count = this.$el.clientWidth / 50 // get the possible items amount, 2 per 100px width

      this.legendDivisor = Math.ceil(array.length / count) || 1 // we'll show every n-th item only
    },

    setWidthNumbers () {
      this.width = this.$el.clientWidth - 64
      this.calculateMonthes(this.visible)
    },

    showTooltip (event) {
      this.tooltip.actual = utils.formatNumber(event.target.getAttribute('data-actual')) // It's not dataset due to IE11
      this.tooltip.forecast = utils.formatNumber(event.target.getAttribute('data-forecast'))
      this.tooltip.x = event.layerX
      this.tooltip.y = event.layerY
      this.tooltip.shift = event.layerX * 100 / this.width
      this.tooltip.date = this.formatDate(event.target.getAttribute('data-timestamp'))
      this.tooltip.isShown = true
    },

    hideTooltip () {
      this.tooltip.isShown = false
    },

    setSliderLeft (x) {
      if (x < 0) x = 0

      this.slider.left = x
      const item = Math.floor(x * this.history.length / 100)
      this.visibleStart = item < 0 ? 0 : item
    },

    setSliderRight (x) {
      if (x > 100) x = 100

      this.slider.right = x
      this.visibleEnd = Math.ceil(x * this.history.length / 100)
    },

    captureOn (event) {
      this.slider.capture = event.target.id
      this.slider.x = event.type !== 'touchstart' ? event.x : event.targetTouches[0].clientX
      this.slider.originalLeft = this.slider.left
      this.slider.originalRight = this.slider.right
    },

    captureOff (event) {
      this.slider.capture = false
    },

    move (event) {
      // obtaining coordinates in event.x, event.y
      if (this.slider.capture) {
        const x = event.type !== 'touchmove' ? event.x : event.targetTouches[0].clientX
        const offset = this.$refs.minimap.getBoundingClientRect().left
        const width = this.$refs.minimap.getBoundingClientRect().width
        const minLength = 100 / this.history.length

        if (this.slider.capture === 'zoom-border-left') {
          const coordinate = x - offset
          const percent = coordinate * 100 / width
          const percent2set = percent > this.slider.right - minLength
            ? this.slider.right - minLength : percent

          this.setSliderLeft(percent2set)
        } else if (this.slider.capture === 'zoom-border-right') {
          const coordinate = x - offset
          const percent = coordinate * 100 / width
          const percent2set = percent < this.slider.left + minLength
            ? this.slider.left + minLength : percent

          this.setSliderRight(percent2set)
        } else if (this.slider.capture === 'zoom-area') {
          const shift = (this.slider.x - x) * 100 / width
          const leftShift = this.slider.originalLeft - shift
          const rightShift = this.slider.originalRight - shift
          const newLeft = leftShift > this.slider.right - minLength
            ? this.slider.right - minLength : leftShift
          const newRight = rightShift < this.slider.left + minLength
            ? this.slider.left + minLength : rightShift

          this.setSliderLeft(newLeft)
          this.setSliderRight(newRight)
        }
      }
    },

    setMinimapParams (history) {
      if (history.length === 0) return

      this.needDatesInCalendar = this.legendDivisor < this.itemsPerMonth
      this.setSliderRight(100)

      const yearsAgo = new Date()
      yearsAgo.setFullYear(yearsAgo.getFullYear() - 2)

      let i = 0
      while (new Date(history[i].timestamp) < yearsAgo) {
        i++
      }
      const newLeft = i * 100 / history.length
      this.setSliderLeft(newLeft)
    }
  },

  mounted () {
    this.setWidthNumbers()
    window.addEventListener('resize', utils.debounce(this.setWidthNumbers, 50))

    if (this.history.length) {
      this.setMinimapParams(this.history)
    }
  },

  watch: {
    history (array) {
      this.setMinimapParams(array)
    },

    visible (array) {
      this.calculateMonthes(array)
      this.needDatesInCalendar = this.legendDivisor < this.itemsPerMonth
    }
  }
}
</script>

<style scoped>
.chart-block {
  color: #909294;
  font-size: 12px;
  line-height: 16px;
}

.chart-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: stretch;
}

.chart {
  flex-shrink: 1;
  flex-grow: 1;
  max-width: 100%;
}

.chart-columns:hover .chart-column--actual {fill: #21a2f8;}
.chart-columns:hover .chart-column--forecast {fill: #3Cb3b2;}

.chart-tooltip {
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.75);
  padding: 8px 10px;
  margin-top: 5px;
  border-radius: 4px;
  transform: translate(-50%, 0);
  color: #fff;
  white-space: nowrap;
}
.chart-tooltip--left {transform: translate(0, 0);}
.chart-tooltip--right {transform: translate(-100%, 0);}

.scale {
  flex-basis: 64px;
  flex-shrink: 0;
  flex-grow: 0;
}

.chart-legend {
  margin-right: 64px;
  margin-bottom: 10px;
}

.minimap {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 64px;
  margin-bottom: 10px;
}

.minimap-slider {
  position: absolute;
  top: 0;
}

.zoom-area {
  cursor: move;
  cursor: grab;
}
.zoom-area:active {
  cursor: move;
  cursor: grabbing;
}

.zoom-border {
  cursor: ew-resize;
}

.minimap-chart,
.minimap-slider,
.minimap-labels,
.chart-labels {
  width: 100%;
  overflow: visible;
}

.legend {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -2px;
  margin-right: 8px;
  vertical-align: middle;
}

.label {
  fill: #606264;
  font: normal 12px Roboto, sans-serif;
}
.label--vertical {transform: translate(0, 4px);}
.label--horizontal {text-anchor: middle;}

.actual {
  background-color: #1192E8;
}

.forecast {
  margin-left: 48px;
  background-color: #2CA3A2;
}

.history-loading {
  width: 100%;
  margin: 20px 0;
}

.history-loading::after {
  content: "";
  display: block;
  margin: 0 auto;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border-color: #f60 transparent;
  animation: rotate 1s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
