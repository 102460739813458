<template>
  <div>
    <div class="economcalendar-row">
      <button class="back"
        @click="goToList()">{{ $t('detailed.back') }}</button>
    </div>

    <ListCaption :isDetailedView="true" />
    <Preloader v-if="$store.state.isLoading" />
    <div class="no-results"
      v-else-if="event.id === undefined">{{ $t('filters.no_data') }}</div>
    <template v-else>
      <DateCaption :date="event.timestamp" :isDetailedView="true" />
      <Row
        :event="event"
        :isDetailedView="true"
        :sameOptions="{sameTime: false, sameCountry: false}" />
    </template>
  </div>
</template>

<script>
import ListCaption from '@/components/ListCaption.vue'
import Row from '@/components/Row.vue'
import DateCaption from '@/components/DateCaption.vue'
import Preloader from '@/components/Preloader.vue'

export default {
  name: 'Event',

  components: {
    ListCaption,
    Row,
    DateCaption,
    Preloader
  },

  props: {
    event: {
      type: Object,
      required: true
    }
  },

  methods: {
    goToList () {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style scoped>
.back {
  margin-bottom: 22px;
  background-color: #fff;
  border: none;
  color: #1192E8;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
}

.back::before {
  content: "";
  position: relative;
  top: -1px;
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-right: 8px;
  border-left: 2px solid #1192E8;
  border-bottom: 2px solid #1192E8;
  transform: rotate(45deg);
}

.no-results {
  padding: 50px 20px;
  text-align: center;
}
</style>
