<template>
  <div class="filters__datespicker">
    <div class="filters__shorthands">
      <div class="filters__shorthand-item"
        v-for="shorthand in $config.dateShorthands"
        :key="shorthand.code"
        :class="{active: isActive(shorthand)}"
        :data-period="shorthand.period"
        :data-shift="shorthand.shift"
        @click="setPeriod(shorthand)">
        {{ $t(`dateShorthands.${shorthand.code}`) }}
      </div>
    </div>

    <div class="filters__datespicker-inputs">
      <label class="filters__datespicker-label">
        <div>{{ $t('filters.from') }}</div>
        <input type="date" name="from" class="filters__datespicker-input"
          v-model="from"
          :placeholder="from"
          :min="supermin"
          :max="to"
          @change="setDate('from')">
      </label>
      <label class="filters__datespicker-label">
        <div>{{ $t('filters.to') }}</div>
        <input type="date" name="to" class="filters__datespicker-input"
          v-model="to"
          :placeholder="to"
          :min="from"
          :max="supermax"
          @change="setDate('to')">
      </label>
    </div>
  </div>
</template>

<script>
import utils from '@/utils'

export default {
  name: 'FilterDatespicker',

  data () {
    return {
      now: new Date()
    }
  },

  computed: {
    from: {
      get () {
        return this.$store.state.proxy.dates.from
      },
      set (value) {
        this.$store.commit('setProxyDates', { from: value })
        this.$emit('filterWasUpdated')
      }
    },

    to: {
      get () {
        return this.$store.state.proxy.dates.to
      },
      set (value) {
        this.$store.commit('setProxyDates', { to: value })
        this.$emit('filterWasUpdated')
      }
    },

    supermin () {
      return new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate() - 90).toISOString().slice(0, 10)
    },

    supermax () {
      return new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate() + 90).toISOString().slice(0, 10)
    }
  },

  methods: {
    setPeriod (shorthand) {
      const from = new Date()
      const to = new Date()

      from.setDate(from.getDate() + shorthand.shift)
      to.setDate(to.getDate() + shorthand.shift + shorthand.period)

      this.$store.commit('setProxyDates', {
        from: from.toISOString().slice(0, 10),
        to: to.toISOString().slice(0, 10)
      })
      this.$emit('filterWasUpdated')
    },

    isActive (shorthand) {
      const from = new Date()
      const to = new Date()

      from.setDate(from.getDate() + shorthand.shift)
      to.setDate(to.getDate() + shorthand.shift + shorthand.period)

      return from.toISOString().slice(0, 10) === this.$store.state.proxy.dates.from &&
        to.toISOString().slice(0, 10) === this.$store.state.proxy.dates.to
    },

    setDate (edge) {
      if (!utils.checkDate(this[edge])) this[edge] = this.$store.state.filters.dates[edge]

      this.$emit('filterWasUpdated')
    }
  }
}
</script>

<style scoped>
.filters__datespicker {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  background: #fff;
  border: 1px solid #CFD1D5;
  color: #111;
  font-size: 13px;
  line-height: 16px;
}

.filters__shorthands {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  background: #fff;
  border-radius: 3px 0 0 3px;
}

.filters__shorthand-item {
  padding: 12px;
  cursor: pointer;
  white-space: nowrap;
}
.filters__shorthand-item:first-child {border-radius: 3px 0 0 0;}
.filters__shorthand-item:last-child {border-radius: 0 0 0 3px;}

.filters__shorthand-item:hover {background-color: #F2F4F6;}

.filters__shorthand-item.active {
  font-weight: bold;
  background-color: #eee;
}

.filters__datespicker-inputs {
  padding: 12px;
  background: #f7f7f7;
  border-radius: 0 3px 3px 0;
}

.filters__datespicker-label {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  white-space: nowrap;
}

.filters__datespicker-input {
  height: 32px;
  width: auto;
  padding: 8px 6px 8px 12px;
  margin-left: 12px;
  border: 1px solid #CFD1D5;
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  outline: none;
}

.filters__datespicker-input:focus,
.filters__datespicker-input:active {
  border: 1px solid #FFA408;
}
</style>
