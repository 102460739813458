<template>
  <div class="filters-countries">
    <div class="filter-countries__search">
      <input type="text" class="filter-countries__search-input"
        :placeholder="$t('filters.search')"
        v-model="searchQuery">
      <icon-search class="filter-countries__search-icon" />
      <icon-x class="filter-countries__search-x"
        v-if="searchQuery !== ''"
        @click.native="searchQuery = ''"/>
    </div>

    <div class="filter-countries__shorthands">
      <a class="filter-countries__shorthand"
        v-for="region in Object.keys($config.regions)"
        :key="region"
        :class="{'selected': selectedLabels.includes(region)}"
        @click.prevent="selectGroup($config.regions[region])">{{ $t(`regions.${region}`) }}</a>
    </div>

    <div class="filter-countries__wrapper">
      <div class="filter-countries__list">
        <label class="filter-countries__label"
          :class="{'filter-countries__label--selected': selectedCountriesList.includes(country.code)}"
          v-for="country in filteredCountries"
          :key="country.code">
          <input type="checkbox"
            :name="country.code"
            :checked="selectedCountriesList.includes(country.code)"
            @change="updateCountries(country.code)">
          <Flag width="22" height="16" class="flag"
            :id="country.code.toLowerCase()" />
          <div>{{ country.name }}</div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { Flag, IconSearch, IconX } from '@/ui'

export default {
  name: 'FilterCountriesSelect',

  components: {
    Flag,
    IconSearch,
    IconX
  },

  data () {
    return {
      searchQuery: ''
    }
  },

  computed: {
    selectedCountriesList () {
      return this.$store.state.proxy.regions
    },

    countries () {
      return this.$config.regions.all.map(el => ({
        code: el,
        name: this.$t(`countries.${el}`),
        groups: Object.keys(this.$config.regions).filter(group => group !== 'all' && this.$config.regions[group].includes(el))
      }))
    },

    filteredCountries () {
      return this.countries.filter(el => {
        return el.code.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          el.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          el.groups.includes(this.searchQuery.toLowerCase())
      })
    },

    selectedLabels () {
      if (
        this.selectedCountriesList.length === this.$config.regions.all.length
      ) {
        return ['all']
      } else {
        const groupNames = []

        Object.keys(this.$config.regions).forEach(listName => {
          const isSubArray = this.$config.regions[listName].every(el => this.selectedCountriesList.includes(el))

          if (isSubArray) {
            groupNames.push(listName)
          }
        })

        return groupNames
      }
    }
  },

  methods: {
    updateCountries (region) {
      let regions2update

      if (this.selectedCountriesList.includes(region)) {
        regions2update = this.selectedCountriesList.filter(el => el !== region)
      } else {
        regions2update = this.selectedCountriesList.concat(region)
      }

      this.$store.commit('setProxyRegions', { regions: regions2update })
      this.$emit('filterWasUpdated')
    },

    selectGroup (group) {
      const allSelected = this.checkIfAllSelected(group)
      let newSelected = this.selectedCountriesList

      if (allSelected) {
        newSelected = newSelected.filter(el => !group.includes(el))
      } else {
        group.forEach(el => {
          if (newSelected.indexOf(el) === -1) newSelected.push(el)
        })
      }

      this.$store.commit('setProxyRegions', { regions: newSelected })
      this.$emit('filterWasUpdated')
    },

    checkIfAllSelected (group) {
      return group.every(el => {
        return this.selectedCountriesList.indexOf(el) !== -1
      })
    }
  }
}
</script>

<style scoped>
.filters-countries {
  background-color: #fff;
  border: 1px solid #CFD1D5;
  color: #111;
  font-size: 13px;
  line-height: 16px;
}

.filter-countries__search {
  position: relative;
  margin: 12px;
}

.filter-countries__search-input {
  width: 100%;
  height: 32px;
  padding: 8px 22px 8px 8px;
  background: #fff;
  border: 0.5px solid #909294;
  border-radius: 4px;
  color: #242526;
  outline: none;
}
.filter-countries__search-input:focus,
.filter-countries__search-input:active {border: 1px solid #FFA408;}

.filter-countries__search-icon {
  position: absolute;
  top: calc(50% - 8px);
  right: 8px;
}

.filter-countries__search-x {
  position: absolute;
  top: calc(50% - 15px);
  right: 1px;
  cursor: pointer;
  border-radius: 4px;
}

.filter-countries__shorthands {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  padding: 0 12px;
  border-bottom: 1px solid #E0E2E4;
}

.filter-countries__shorthand {
  flex-grow: 1;
  padding: 0 0 10px;
  border-bottom: 2px solid transparent;
  color: #606264;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}
.filter-countries__shorthand.selected {
  border-color: #606264;
}

.filter-countries__wrapper { /* Just to round scroll bar's borders */
  overflow: hidden;
  border-radius: 0 0 3px 3px;
}

.filter-countries__list {
  height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
}

.filter-countries__label {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 40px;
  padding: 0 12px;
}
.filter-countries__label--selected {background: #F2F4F6;}

input[type="checkbox"] {
  opacity: 1;
  position: static;
  z-index: 1; /* override the app's natives */
}

.flag {
  margin: 0 12px;
}

  .filters__countries-shorthands {
    text-align: left;
    margin-top: 0;
    margin-bottom: 10px;
  }

  a {
    display: inline-block;
    margin-right: 10px;
    color: #1192E8;
  }

  .filters__countries-list {
    column-count: 3;
    text-align: left;
  }

  .filters__countries-item {
    white-space: nowrap;
  }
</style>
