import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    filters: {
      dates: {
        from: '',
        to: ''
      },
      regions: [],
      importance: ['high', 'medium'],
      timezone: ''
    },
    proxy: {
      dates: {
        from: '',
        to: ''
      },
      regions: [],
      importance: [],
      timezone: ''
    },
    events: [],
    widgetWidth: 0,
    language: 'en',
    app: '',
    isLoading: false,
    headerOffset: 0
  },

  mutations: {
    // Filters setters
    setDates (state, payload) {
      if (payload.from !== undefined) {
        Vue.set(state.filters.dates, 'from', payload.from)
      }
      if (payload.to !== undefined) {
        Vue.set(state.filters.dates, 'to', payload.to)
      }
    },
    setRegions (state, payload) {
      Vue.set(state.filters, 'regions', payload.regions.sort())
    },
    setImportance (state, payload) {
      Vue.set(state.filters, 'importance', payload.importance.sort())
    },
    setTimezone (state, payload) {
      Vue.set(state.filters, 'timezone', payload.timezone)
    },
    // Proxy setters
    setProxyDates (state, payload) {
      if (payload.from !== undefined) {
        Vue.set(state.proxy.dates, 'from', payload.from)
      }
      if (payload.to !== undefined) {
        Vue.set(state.proxy.dates, 'to', payload.to)
      }
    },
    setProxyRegions (state, payload) {
      Vue.set(state.proxy, 'regions', payload.regions.sort())
    },
    setProxyImportance (state, payload) {
      Vue.set(state.proxy, 'importance', payload.importance.sort())
    },
    setProxyTimezone (state, payload) {
      Vue.set(state.proxy, 'timezone', payload.timezone)
    },

    setEvents (state, payload) {
      state.events = payload.events
    },
    addOneDay (state, payload) {
      state.events.push(...payload.day)
    },
    setWidgetWidth (state, payload) {
      state.widgetWidth = payload.widgetWidth
    },
    setLanguage (state, payload) {
      state.language = payload.language
    },
    setApp (state, payload) {
      state.app = payload.app
    },
    setLoadState (state, payload) {
      state.isLoading = payload.isLoading
    },
    setHeaderOffset (state, { headerOffset }) {
      state.headerOffset = headerOffset
    }
  },

  actions: {
    getEvents ({ state, commit }) {
      if (state.filters.dates.from === '' || state.filters.dates.to === '') return

      commit('setLoadState', { isLoading: true })

      const src = Vue.prototype.$config.default.src

      api.getAll(src, {
        start: state.filters.dates.from,
        end: state.filters.dates.to,
        lang: state.language,
        timezone: state.filters.timezone,
        importance: state.filters.importance.join(','),
        region: state.filters.regions.join(','),
        limit: state.filters.limit,
        offset: state.filters.offset
      })
        .then(data => {
          commit('setEvents', { events: data })
          commit('setLoadState', { isLoading: false })
        })
    }
  }
})
