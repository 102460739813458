<template>
  <div>
    <div class="countries-shorthands">
      <a class="countries-shorthands__item"
        v-for="region in Object.keys($config.regions)"
        :key="region"
        :class="{'countries-shorthands__item--selected': selectedLabels.includes(region)}"
        @click.prevent="selectGroup($config.regions[region])">{{ $t(`regions.${region}`) }}</a>
    </div>

    <div class="countries-search">
      <input type="text" class="countries-search__input"
        :placeholder="$t('filters.search')"
        v-model="searchQuery">
      <icon-search class="countries-search__icon" />
      <icon-x class="countries-search__x"
        v-if="searchQuery !== ''"
        @click.native="searchQuery = ''"/>
    </div>

    <div class="countries-list">
      <div class="countries-list__item"
        v-for="country in filteredCountries"
        :key="country.code"
        :class="{'countries-list__item--selected': isActive(country.code)}"
        @click="updateCountries(country.code)">
        <Flag width="21" height="15" class="flag"
          :id="country.code.toLowerCase()"/>
        <div class="countries-list__country">
          {{ country.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Flag, IconSearch, IconX } from '@/ui'

export default {
  name: 'FilterCountriesSelect',

  components: {
    Flag,
    IconSearch,
    IconX
  },

  data () {
    return {
      searchQuery: ''
    }
  },

  computed: {
    selectedCountriesList () {
      return this.$store.state.proxy.regions
    },

    countries () {
      return this.$config.regions.all.map(el => ({
        code: el,
        name: this.$t(`countries.${el}`),
        groups: Object.keys(this.$config.regions).filter(group => group !== 'all' && this.$config.regions[group].includes(el))
      }))
    },

    filteredCountries () {
      return this.countries.filter(el => {
        return el.code.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          el.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          el.groups.includes(this.searchQuery.toLowerCase())
      })
    },

    selectedLabels () {
      if (
        this.selectedCountriesList.length === this.$config.regions.all.length
      ) {
        return ['all']
      } else {
        const groupNames = []

        Object.keys(this.$config.regions).forEach(listName => {
          const isSubArray = this.$config.regions[listName].every(el => this.selectedCountriesList.includes(el))

          if (isSubArray) {
            groupNames.push(listName)
          }
        })

        return groupNames
      }
    }
  },

  methods: {
    isActive (region) {
      return this.selectedCountriesList.indexOf(region) !== -1
    },

    updateCountries (region) {
      let regions2update

      if (this.isActive(region)) {
        regions2update = this.selectedCountriesList.filter(el => el !== region)
      } else {
        regions2update = this.selectedCountriesList.concat(region)
      }

      this.$store.commit('setProxyRegions', { regions: regions2update })
      this.$emit('filterWasUpdated')
    },

    selectGroup (group) {
      const allSelected = this.checkIfAllSelected(group)
      let newSelected = this.selectedCountriesList

      if (allSelected) {
        newSelected = newSelected.filter(el => !group.includes(el))
      } else {
        group.forEach(el => {
          if (newSelected.indexOf(el) === -1) newSelected.push(el)
        })
      }

      this.$store.commit('setProxyRegions', { regions: newSelected })
      this.$emit('filterWasUpdated')
    },

    checkIfAllSelected (group) {
      return group.every(el => {
        return this.selectedCountriesList.indexOf(el) !== -1
      })
    }
  }
}
</script>

<style scoped>
.countries-list {}

.countries-shorthands {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 8px;
}

.countries-shorthands__item {
  margin-right: 10px;
  color: #1192e8;
  cursor: pointer;
  white-space: nowrap;
}
.countries-shorthands__item:last-child {margin-right: 0;}
@media screen and (max-width: 450px) {
  .countries-shorthands__item {
    font-size: 13px;
  }
}

.countries-shorthands__item--selected {
  color: #000;
  font-weight: 500;
}

.countries-search {
  position: relative;
  margin: 12px 0;
}

.countries-search__input {
  width: 100%;
  height: 32px;
  padding: 8px 30px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #CFD1D5;
  color: #111;
  font-size: 14px;
  line-height: 18px;
  outline: none;
}
.countries-search__input::placeholder {color: #909294;}

.countries-search__icon {
  position: absolute;
  top: calc(50% - 8px);
  left: 8px;
}

.countries-search__x {
  position: absolute;
  top: calc(50% - 15px);
  right: 0;
  background-color: transparent;
  cursor: pointer;
}

.countries-list__item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  margin: 8px 0;
  padding: 0 0 0 30px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}
.countries-list__item:first-child {margin-top: 0;}
.countries-list__item:last-child {margin-bottom: 0;}

.countries-list__item::before {
  content: '';
  position: absolute;
  top: calc(50% - 6px);
  left: 1px;
  width: 10px;
  height: 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
}
.countries-list__item--selected::after {
  content: '';
  position: absolute;
  top: calc(50% - 9px);
  left: 2px;
  width: 12px;
  height: 7px;
  border-left: 2px solid #e8582f;
  border-bottom: 2px solid #e8582f;
  transform: rotate(-45deg);
}

.flag {
  flex-shrink: 0;
  margin-right: 12px;
  box-shadow: inset 0 0 0 1px #000;
}

.countries-list__country {
  font-size: 17px;
  line-height: 22px;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.countries-list__item--selected .countries-list__country {font-weight: 500;}
</style>
