<template>
  <div class="item__impact-tip">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ItemImpactTip'
}
</script>

<style scoped>
.item__impact-tip {
  display: none;
  position: absolute;
  bottom: 47px;
  left: -8px;
  z-index: 5;
  width: 200px;
  padding: 12px;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(234, 87, 36, 0.05), 0px 4px 24px rgba(0, 0, 0, 0.16), 0px 1px 8px rgba(0, 0, 0, 0.08);
  color: #606264;
  font-size: 12px;
  text-align: left;
  line-height: 1.33;
  white-space: normal;
}
</style>
