<template>
  <div>
    <div class="cell__importance"></div>
    <div class="cell__grow details">
      <div class="details__links economcalendar-row">
        <div>
          <button class="btn-switch"
            :class="{'btn-switch--active': chartIsOpen}"
            @click="showHistory('chart')"><icon-chart /></button>
          <button class="btn-switch"
            :class="{'btn-switch--active': tableIsOpen}"
            @click="showHistory('table')"><icon-table /></button>
        </div>

        <div>
          <button class="btn-action"
            @click="copyEventAddress(event.id, $event)"><icon-copy /> {{ $t('detailed.copy') }}</button>
          <button class="btn-action"
            @click="downloadFile('xlsx')"><icon-download /> {{ $t('detailed.download_xls') }}</button>
          <button class="btn-action"
            @click="downloadFile('csv')"><icon-download /> {{ $t('detailed.download_csv') }}</button>
        </div>
      </div>

      <HistoryChart v-if="chartIsOpen" :loaded="historyLoaded" :history="historyData" />
      <HistoryTable v-if="tableIsOpen" :loaded="historyLoaded" :history="[...historyData].reverse()" />

      <div class="details__description">{{ event.description }}</div>

      <ItemDetailesImpact class="details__impact"
        v-if="event.impacts && event.impacts.length > 0"
        :impact="event.impacts" />

      <div class="details__cta" v-if="event.impacts && event.impacts.length > 0">
        <a class="details__cta-link" href="#" data-icid="button"
          :data-icid-id="`btn_wdg_eccal_goto_${event.impacts[0].name.toLowerCase().replace(/\//g, '')}`"
          @click.prevent="followLink(`https://${$store.state.app}${event.impacts[0].deeplink}`)">
          {{ $t('impact.goto_instrument', {instrument: event.impacts[0].name}) }}
          <icon-arrow />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ItemDetailesImpact from '@/components/ItemDetailesImpact.vue'
import HistoryChart from '@/components/HistoryChart.vue'
import HistoryTable from '@/components/HistoryTable.vue'
import { IconArrow, IconChart, IconTable, IconCopy, IconDownload } from '@/ui'
import utils from '@/utils'
import api from '@/api'
import '@/assets/sizes.css'

export default {
  name: 'ItemDetailes',

  components: {
    ItemDetailesImpact,
    HistoryChart,
    HistoryTable,
    IconArrow,
    IconChart,
    IconTable,
    IconCopy,
    IconDownload
  },

  props: {
    event: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      historyData: [],
      historyLoaded: false,
      chartIsOpen: false,
      tableIsOpen: false
    }
  },

  methods: {
    followLink (link) {
      window.open(link, '_blank')
    },

    showHistory (type) {
      if (this.historyData.length === 0) {
        api.getEventData({
          id: this.event.id,
          lang: this.$store.state.language,
          src: this.$config.default.src
        })
          .then(data => {
            this.historyData = data
            this.historyLoaded = true
          })
      }

      if (type === 'chart') {
        this.tableIsOpen = false
        this.chartIsOpen = !this.chartIsOpen
      }
      if (type === 'table') {
        this.chartIsOpen = false
        this.tableIsOpen = !this.tableIsOpen
      }
    },

    copyEventAddress (id, event) {
      const link = this.$router.resolve({ name: 'Single', params: { id: id } }).href

      return utils.copyEventAddress(link, event.target)
    },

    downloadFile (type) {
      utils.download(`${this.$config.default.src}/api/events/${this.event.id}/history/?format=${type}`, `${this.event.title}.${type}`)
    }
  }
}
</script>

<style scoped>
.cell__grow {
  margin: 0 16px 32px;
  border-top: 1px solid #E0E2E4;
}

.details {
  padding-top: 12px;
  font-size: 14px;
  line-height: 20px;
}

.details__links {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn-switch,
.btn-action {
  margin-right: 8px;
  cursor: pointer;
  outline: none;
}

.btn-switch {
  padding: 3px 7px;
  background: #fff;
  border: 1px solid #B7BBC0;
  border-radius: 4px;
}

.btn-switch:hover {
  background: #f7f7f7;
}

.btn-switch--active,
.btn-switch--active:hover {
  background: #EA5724;
  border: 1px solid #EA5724;
}

.btn-switch svg,
.btn-action svg {
  vertical-align: middle;
  transition: all 0.5s ease;
}

.btn-switch--active svg,
.btn-action--active svg {
  fill: #fff;
}

.btn-action {
  padding: 8px 12px 4px;
  background: #fff;
  border: 0;
  border-radius: 16px;
  color: #1192E8;
  transition: all 0.5s ease;
}
.btn-action:hover {
  background: #F0F2F4;
}
.btn-action--active,
.btn-action--active:hover {
  background-color: #1192E8;
  color: #fff;
}

.btn-action svg {
  margin: -2px 0 2px;
  vertical-align: middle;
}

.details__description {
  margin: 16px 0;
}

.details__impact {
  margin: 16px 0;
  padding: 10px 16px;
  background: #F4F4F5;
  border-radius: 4px;
}

.details__cta-link {
  display: inline-block;
  padding: 14px 32px;
  box-sizing: border-box;
  background: #EA5724;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  border: 0;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}
.details__cta-link:hover {
  opacity: 0.9;
}

.details__cta-link svg {
  vertical-align: middle;
}
</style>
