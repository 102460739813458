<template>
  <div class="filters" :style="{top: `${headerOffset}px`}">
    <!-- <div class="filters__caption">{{ $t('filters.filters') }}</div> -->

    <div class="filter__container">
      <button class="filters__button filters__button--dates-picker"
        :title="datesLabel"
        @click="showFilterDropDown('datespicker')">
        <icon-datepicker /> {{ datesLabel }}
      </button>

      <FilterDatespicker class="filter__dropdown"
        v-if="dropdownIsActive === 'datespicker'"
        v-click-outside="closePopup"
        @filterWasUpdated="checkIfProxiesAreUpdated" />
    </div>

    <div class="filter__container filter__container--responsive">
      <button class="filters__button filters__button--responsive"
        :title="countriesLabel"
        @click="showFilterDropDown('countrySelect')">
        {{ countriesLabel }}
        {{ countriesCount }}
      </button>

      <FilterCountriesSelect class="filter__dropdown"
        v-if="dropdownIsActive === 'countrySelect'"
        v-click-outside="closePopup"
        @filterWasUpdated="checkIfProxiesAreUpdated" />
    </div>

    <div class="filter__container">
      <button class="filters__button"
        :title="importancesLabel"
        @click="showFilterDropDown('importance')">
        {{ importancesLabel }}
      </button>

      <FilterImportance class="filter__dropdown"
        v-if="dropdownIsActive === 'importance'"
        v-click-outside="closePopup"
        @filterWasUpdated="checkIfProxiesAreUpdated" />
    </div>

    <div class="filter__container">
      <button class="filters__button"
        :title="timezoneLabel"
        @click="showFilterDropDown('timezone')">
        {{ timezoneLabel }}
      </button>

      <FilterTimezones class="filter__dropdown"
        v-if="dropdownIsActive === 'timezone'"
        v-click-outside="closePopup"
        @filterWasUpdated="checkIfProxiesAreUpdated" />
    </div>

    <div class="filter__container filter__container--actions" v-if="filtersIsChanged">
      <button class="filters__button filters__button--actions" @click="applyFilters()">{{ $t('filters.apply') }}</button>
      <button class="filters__clear" @click="clearFilters()">{{ $t('filters.cancel') }}</button>
    </div>
  </div>
</template>

<script>
import FilterDatespicker from '@/components/FilterDatespicker.vue'
import FilterCountriesSelect from '@/components/FilterCountriesSelect.vue'
import FilterImportance from '@/components/FilterImportance.vue'
import FilterTimezones from '@/components/FilterTimezones.vue'
import { IconDatepicker } from '@/ui'
import { minimalTimezoneSet } from 'compact-timezone-list'

export default {
  name: 'Filters',

  components: {
    FilterDatespicker,
    FilterCountriesSelect,
    FilterImportance,
    FilterTimezones,
    IconDatepicker
  },

  data () {
    return {
      dropdownIsActive: null,
      filtersIsChanged: false
    }
  },

  computed: {
    filters () {
      return this.$store.state.filters
    },

    proxy () {
      return this.$store.state.proxy
    },

    locale () {
      return this.$store.state.language
    },

    datesLabel () {
      const from = this.proxy.dates.from !== '' ? new Date(this.proxy.dates.from) : false
      const to = this.proxy.dates.to !== '' ? new Date(this.proxy.dates.to) : false

      if (!from && !to) {
        return this.$t('filters.dates')
      } else if (!from) {
        return this.$t('filters.all_till') + ' ' + this.formatDate(to)
      } else if (!to) {
        return this.$t('filters.all_from') + ' ' + this.formatDate(from)
      } else if (from.getTime() === to.getTime()) {
        return from.toLocaleDateString(this.locale, { weekday: 'short', month: 'short', day: 'numeric' })
      } else {
        // return this.$t('filters.dates') + ': ' + this.formatDate(from) + ' – ' + this.formatDate(to)
        return this.formatDate(from) + ' – ' + this.formatDate(to)
      }
    },

    countriesCount () {
      const max = this.$config.regions.all.length
      const selected = this.proxy.regions.length

      if (selected === 0 || selected === max) return `(${max})`
      else return `(${selected})`
    },

    countriesLabel () {
      if (
        this.proxy.regions.length === this.$config.regions.all.length ||
        this.proxy.regions.length === 0
      ) {
        return this.$t('filters.all_countries')
      } else {
        const label = []
        const list = [...this.proxy.regions]

        Object.keys(this.$config.regions).forEach(listName => {
          const isSubArray = this.$config.regions[listName].every(el => list.includes(el))

          if (isSubArray) {
            label.push(listName.toUpperCase())
            this.$config.regions[listName].forEach(el => {
              list.splice(list.indexOf(el), 1)
            })
          }
        })

        return `${this.$t('filters.countries')}: ${label.concat(list).join(', ')}`
      }
    },

    importancesLabel () {
      if (this.proxy.importance.length > 0 && this.proxy.importance.length < 3) {
        return this.proxy.importance.map(i => this.$t(`importance.${i}`)).join(', ')
      } else {
        return this.$t('filters.all_importances')
      }
    },

    timezoneLabel () {
      const tzObject = minimalTimezoneSet.find(tz => tz.offset === this.proxy.timezone)

      return `GMT ${this.proxy.timezone} ${this.$t(`timezones.${tzObject.tzCode}`).split('/')[1]}`
    },

    headerOffset () {
      return this.$store.state.headerOffset
    }
  },

  methods: {
    showFilterDropDown (filterName) {
      this.dropdownIsActive = filterName
    },

    closePopup (event) {
      this.dropdownIsActive = false
    },

    formatDate (date) {
      return date.toLocaleDateString(this.locale, { month: 'short', day: 'numeric' })
    },

    checkIfProxiesAreUpdated () {
      const equalCountries = JSON.stringify(this.filters.regions) === JSON.stringify(this.proxy.regions) || ([0, this.$config.regions.all.length].indexOf(this.proxy.regions.length) !== -1 && [0, this.$config.regions.all.length].indexOf(this.filters.regions.length) !== -1)
      const equalImportance = JSON.stringify(this.filters.importance) === JSON.stringify(this.proxy.importance) || ([0, this.$config.importance.length].indexOf(this.proxy.importance.length) !== -1 && [0, this.$config.importance.length].indexOf(this.filters.importance.length) !== -1)
      const equalDates = this.filters.dates.from === this.proxy.dates.from && this.filters.dates.to === this.proxy.dates.to
      const equalTimezone = this.filters.timezone === this.proxy.timezone

      this.filtersIsChanged = !equalDates || !equalCountries || !equalImportance || !equalTimezone
    },

    setProxies () { // set visible filters with actual values
      this.$store.commit('setProxyDates', { from: this.filters.dates.from, to: this.filters.dates.to })
      this.$store.commit('setProxyRegions', { regions: [...this.filters.regions] })
      this.$store.commit('setProxyImportance', { importance: [...this.filters.importance] })
      this.$store.commit('setProxyTimezone', { timezone: this.filters.timezone })
    },

    clearFilters () {
      this.setProxies()
      this.filtersIsChanged = false
    },

    applyFilters () {
      this.$store.commit('setDates', { from: this.proxy.dates.from, to: this.proxy.dates.to })
      this.$store.commit('setRegions', { regions: [...this.proxy.regions] })
      this.$store.commit('setImportance', { importance: [...this.proxy.importance] })
      this.$store.commit('setTimezone', { timezone: this.proxy.timezone })
      this.$store.dispatch('getEvents')
      this.filtersIsChanged = false
      this.closePopup()
    }
  },

  created () {
    this.setProxies()
  }
}
</script>

<style scoped>
.filters {
  position: sticky;
  z-index: 5;
  background-color: #fff;
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.filters >>> * {
  box-sizing: border-box;
}

/* .filters__caption {
  margin-right: 8px;
} */

.filter__container {
  flex-shrink: 0;
  position: relative;
  margin-right: 12px;
  min-width: 0;
}
.filter__container:last-child {margin-right: 0;}
.filter__container--actions {margin-left: auto;}
.filter__container--responsive {flex-shrink: 1;}

.filters__button {
  max-width: 100%;
  margin: 0;
  padding: 8px 16px;
  background-color: #fff;
  border: 1px solid #CFD1D5;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
}
.filters__button:hover {
  background-color: #f4f4f4;
}
.filters__button:focus {
  background-color: #fbddd3;
}

.filters__button--responsive {
  overflow: hidden;
  text-overflow: ellipsis;
}

.filters__button--dates-picker {
  padding-left: 12px;
}

.filters__button--dates-picker svg {
  margin: -6px 0 -4px;
  vertical-align: middle;
}

.filters__button--actions {
  margin-right: 12px;
  background: #EA5724;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 16%);
  border: 0;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
}
.filters__button--actions:hover {
  opacity: .9;
  background: #EA5724;
}

.filters__clear {
  position: relative;
  width: 20px;
  height: 20px;
  background: transparent;
  border: none;
  text-indent: -9999%;
  overflow: hidden;
  cursor: pointer;
  opacity: .5;
}
.filters__clear:hover {opacity: 1;}
.filters__clear::after,
.filters__clear::before {
  content: '';
  position: absolute;
  left: calc(50% - 10px);
  display: block;
  width: 20px;
  height: 2px;
  background: #666;
}
.filters__clear::after {
  top: 9px;
  transform: rotate(45deg);
}
.filters__clear::before {
  bottom: 9px;
  transform: rotate(-45deg);
}

.filter__dropdown {
  position: absolute;
  z-index: 2;
  margin-top: 3px;
  border-radius: 4px;
}
</style>
