<template>
  <div class="timezones-list">
    <div class="timezones-list__item"
      v-for="tz in timezones"
      :key="tz.label"
      :class="{'timezones-list__item--selected': tz.offset === timezone}"
      @click="timezone = tz.offset">
      <div class="timezones-list__item-shift">
        GMT {{ tz.offset }}
      </div>
      <div class="timezones-list__item-name">{{ $t(`timezones.${tz.tzCode}`) }}</div>
    </div>
  </div>
</template>

<script>
import { minimalTimezoneSet } from 'compact-timezone-list'

export default {
  name: 'FilterTimezones',

  data () {
    return {
      timezones: minimalTimezoneSet
    }
  },

  computed: {
    timezone: {
      get () {
        return this.$store.state.proxy.timezone
      },
      set (value) {
        this.$store.commit('setProxyTimezone', { timezone: value })
        this.$emit('filterWasUpdated')
      }
    }
  },

  mounted () {
    const selected = document.querySelector('.timezones-list__item--selected')

    selected.scrollIntoView({
      block: 'center',
      inline: 'nearest',
      behavior: 'smooth'
    })
  }
}
</script>

<style scoped>
.timezones-list__item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  margin-bottom: 8px;
  border-radius: 4px;
  font-size: 17px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
}
.timezones-list__item:last-child {margin-bottom: 0;}

.timezones-list__item--selected {font-weight: 500;}

.timezones-list__item::before {
  content: '';
  position: absolute;
  top: calc(50% - 6px);
  right: 0;
  width: 10px;
  height: 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
}

.timezones-list__item--selected::after {
  content: '';
  position: absolute;
  top: calc(50% - 9px);
  right: -1px;
  width: 12px;
  height: 7px;
  border-left: 2px solid #e8582f;
  border-bottom: 2px solid #e8582f;
  transform: rotate(-45deg);
}

.timezones-list__item-shift {
  margin-right: 8px;
}
.timezones-list__item-name {
  margin-right: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #909294;
}

.timezones-list__item--selected .timezones-list__item-shift,
.timezones-list__item--selected .timezones-list__item-name {font-weight: 500;}
</style>
