<template>
  <div>
    {{ $t('impact.on') }}
    <Instrument width="24" height="24" class="instrument" :id="impact[0].symbol.toLowerCase()" />
    <strong>{{ impact[0].name }}</strong>:
    <span v-html="substituteTip"></span>
  </div>
</template>

<script>
import { Instrument } from '@/ui'

export default {
  name: 'ItemImpactDetailed',

  components: {
    Instrument
  },

  props: {
    impact: {
      type: Array,
      required: true
    }
  },

  computed: {
    pips () {
      return this.impact[0].value
    },

    substituteTip () {
      const pipsStr = `<strong>${this.$t('impact.pips', { pips: this.pips })}</strong>`

      return this.$t('impact.measured', { pips: pipsStr })
    }
  }
}
</script>

<style scoped>
.instrument {
  margin: 0 4px;
  vertical-align: middle;
}
</style>
