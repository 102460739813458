import Vue from 'vue'
import VueRouter from 'vue-router'
import All from '../views/All.vue'
import Single from '../views/Single.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'All',
    component: All
  },
  {
    path: '/event/:id',
    name: 'Single',
    // route level code-splitting
    // this generates a separate chunk (single.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "single" */ '../views/Single.vue')
    component: Single
  }
]

const router = new VueRouter({
  routes
})

export default router
