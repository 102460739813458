<template>
  <div class="information">
    <strong>i</strong>

    <div class="information__tip">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoTip'
}
</script>

<style scoped>
.information {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  box-sizing: content-box;
  margin-left: 6px;
  border: 1px solid #909294;
  border-radius: 50%;
  line-height: 11px;
  text-align: center;
}

strong {
  color: #909294;
  font-size: 9px;
}

.information__tip {
  display: none;
  position: absolute;
  top: 22px;
  right: -12px;
  z-index: 1;
  min-width: 100px;
  padding: 12px;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(234, 87, 36, 0.05), 0px 4px 24px rgba(0, 0, 0, 0.16), 0px 1px 8px rgba(0, 0, 0, 0.08);
  color: #606264;
  font-size: 12px;
  text-align: left;
  line-height: 1.33;
  white-space: normal;
}

@media screen and (max-width: 899px) {
  .information__tip {
    width: auto;
    max-width: 140px;
  }
}

.information:hover .information__tip {
  display: block;
}

.information__tip::before {
  content: "";
  position: absolute;
  top: -8px;
  right: 12px;
  display: block;
  width: 0;
  height: 0;
  border-width: 0 8px 8px 8px;
  border-color: #fff transparent;
  border-style: solid;
}
</style>
