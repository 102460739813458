<template>
  <div>
    <span>{{ impact[0].name }}</span>
    <Instrument width="16" height="16" class="instrument" :id="impact[0].symbol.toLowerCase()" />
  </div>
</template>

<script>
import { Instrument } from '@/ui'

export default {
  name: 'ItemImpact',

  components: {
    Instrument
  },

  props: {
    impact: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
div {
  cursor: pointer;
}

.instrument {
  margin: 0 0 0 4px;
  vertical-align: middle;
}

span {
  border-bottom: 1px dotted #666;
}
</style>
