const now = new Date()

export default {
  default: {},
  regions: {
    all: ['US', 'AU', 'AT', 'BE', 'BR', 'CA', 'CN', 'CY', 'CZ', 'DK', 'EA', 'FI', 'FR', 'DE', 'GR', 'HK', 'HU', 'IN', 'ID', 'IE', 'IT', 'JP', 'LU', 'MX', 'NL', 'NZ', 'NO', 'PL', 'PT', 'RO', 'RU', 'SG', 'ZA', 'KR', 'ES', 'SE', 'CH', 'TR', 'GB'],
    g7: ['US', 'CA', 'FR', 'DE', 'IT', 'JP', 'GB'],
    eu: ['AT', 'BE', 'CY', 'CZ', 'DK', 'EA', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LU', 'NL', 'PL', 'PT', 'RO', 'ES', 'SE'],
    la: ['BR', 'MX'],
    as: ['CN', 'HK', 'IN', 'ID', 'JP', 'SG', 'KR', 'TR']
  },
  importance: ['high', 'medium', 'low'],
  dateShorthands: [
    {
      code: 'today',
      period: 0,
      shift: 0
    },
    {
      code: 'tomorrow',
      period: 0,
      shift: 1
    },
    {
      code: 'twodays',
      period: 1,
      shift: 0
    },
    {
      code: 'week',
      period: 6,
      shift: -(now.getDay() + 6) % 7
    },
    {
      code: 'nextweek',
      period: 6,
      shift: 7 - (now.getDay() + 6) % 7
    },
    {
      code: 'month',
      period: new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate(),
      shift: 1 - now.getDate()
    }
  ],
  instruments: { // TODO remove if it's useless
    audusd: 'AUD/USD',
    brn: 'BRN',
    bitcoin: 'Bitcoin',
    cocoa: 'COCOA',
    coffee: 'COFFEE',
    corn: 'CORN',
    es: 'ES',
    eurusd: 'EUR/USD',
    fce: 'FCE',
    fdax: 'FDAX',
    gbpusd: 'GBP/USD',
    hsi: 'HSI',
    ibx: 'IBX',
    mib: 'MIB',
    ng: 'NG',
    nkd: 'NKD',
    nq: 'NQ',
    nzdusd: 'NZD/USD',
    russia50: 'Russia50',
    soybean: 'SOYBEAN',
    sugar: 'SUGAR',
    usdcad: 'USD/CAD',
    usdchf: 'USD/CHF',
    usdclp: 'USD/CLP',
    usdcnh: 'USD/CNH',
    usddkk: 'USD/DKK',
    usdjpy: 'USD/JPY',
    usdkrw: 'USD/KRW',
    usdmxn: 'USD/MXN',
    usdnok: 'USD/NOK',
    usdpln: 'USD/PLN',
    usdrub: 'USD/RUB',
    usdsgd: 'USD/SGD',
    usdsek: 'USD/SEK',
    usdtry: 'USD/TRY',
    usdx: 'USDX',
    usdzar: 'USD/ZAR',
    wheat: 'WHEAT',
    xauusd: 'XAU/USD',
    xu: 'XU',
    ym: 'YM',
    z: 'Z'
  }
}
