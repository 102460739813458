<template>
  <div>
    <table class="history-table">
      <tr class="history-caption" v-if="history.length > 0">
        <td>{{ $t('caption.date') }}</td>
        <td>
          {{ $t('caption.last') }}
          <InfoTip>{{ $t('caption.last_tip') }}</InfoTip>
        </td>
        <td>
          {{ $t('caption.forecast') }}
          <InfoTip>{{ $t('caption.forecast_tip') }}</InfoTip>
        </td>
        <td>
          {{ $t('caption.fact') }}
          <InfoTip>{{ $t('caption.fact_tip') }}</InfoTip>
        </td>
      </tr>
      <tr class="history-row"
        v-for="line in paginatedData"
        :key="line.timestamp">
        <td>{{ formatDate(line.timestamp) }}</td>
        <td class="digit-value">{{ formatNumber(line.previous) }}</td>
        <td class="digit-value">{{ formatNumber(line.forecast) }}</td>
        <td class="digit-value">{{ formatNumber(line.actual) }}</td>
      </tr>
      <tr class="history-pager">
        <td colspan="4" v-if="!loaded">
          <div class="history-loading"></div>
        </td>
        <td colspan="4" v-else-if="history.length > 0">
          <span class="history-pager-info"
            v-html="$t('detailed.showing', [fromTo, history.length])"></span>

          <button class="history-pagination"
            :disabled="pageNumber === 0"
            @click="prevPage">
            <icon-left />
          </button>
          <button class="history-pagination"
            :disabled="pageNumber >= pagesCount - 1"
            @click="nextPage">
            <icon-right />
          </button>
        </td>
        <td colspan="4" v-else>
          {{ $t('filters.no_data') }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import InfoTip from '@/components/InfoTip.vue'
import { IconLeft, IconRight } from '@/ui'
import utils from '@/utils'

export default {
  name: 'HistoryTable',

  components: {
    InfoTip,
    IconLeft,
    IconRight
  },

  props: {
    history: {
      type: Array,
      required: true
    },

    loaded: {
      type: Boolean,
      default: false
    },

    size: {
      type: Number,
      default: 5
    }
  },

  computed: {
    locale () {
      return this.$store.state.language
    },

    pagesCount () {
      return Math.ceil(this.history.length / this.size)
    },

    paginatedData () {
      const start = this.pageNumber * this.size
      const end = start + this.size

      return this.history.slice(start, end)
    },

    fromTo () {
      const start = this.pageNumber * this.size
      const end = (this.pageNumber >= this.pagesCount - 1) ? this.history.length : start + this.size

      return `${start + 1}-${end}`
    }
  },

  data () {
    return {
      pageNumber: 0
    }
  },

  methods: {
    formatDate (timestamp) {
      return new Date(timestamp).toLocaleDateString(this.locale, { month: 'short', day: 'numeric', year: 'numeric' })
    },

    formatNumber (n) {
      return utils.formatNumber(n)
    },

    nextPage () {
      this.pageNumber++
    },

    prevPage () {
      this.pageNumber--
    }
  }
}
</script>

<style scoped>
.history-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  line-height: 16px;
}

.history-table td {
  padding: 0 16px;
}
@media screen and (max-width: 500px) {
  .history-table td {padding: 0 5px;}
}

.history-caption,
.history-row,
.history-pager {
  height: 40px;
  border: 1px solid #E0E2E4;
  white-space: nowrap;
}

.history-caption {
  border-bottom: 2px solid #E0E2E4;
}

.history-row {
  font-weight: 500;
}
@media screen and (max-width: 500px) {
  .history-row {font-weight: normal;}
}

.history-table tr:nth-child(even) {
  background-color: #F0F2F4;
}

.digit-value {
  width: 1%;
  min-width: 64px;
  font-size: 14px;
}
@media screen and (max-width: 500px) {
  .digit-value {
    min-width: auto;
    font-size: 12px;
  }
}

.history-pager {
  line-height: 24px;
}

.history-pager-info {
  margin-right: 12px;
}

.history-pagination {
  border: 0;
  padding: 0;
  margin: 0 4px;
  background: none;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.history-pagination:disabled {
  opacity: 0.3;
}

.history-loading::after {
  content: "";
  display: block;
  margin: 0 auto;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border-color: #f60 transparent;
  animation: rotate 1s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
