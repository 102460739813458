<template>
  <div class="filters">
    <button class="filters__button"
      @click="openFilters()">{{ $t('filters.filters') }}</button>

    <div class="filters__wrapper" v-if="filtersIsOpen"
      :style="{top: `${headerOffset}px`}">
      <Header>
        <template v-slot:left>
          <button class="filters__header-link" @click="clearFilters()">{{ $t('filters.cancel') }}</button>
        </template>

        {{ $t('filters.filters')}}

        <template v-slot:right v-if="filtersIsChanged">
          <button class="filters__header-link" @click="applyFilters()">{{ $t('filters.apply') }}</button>
        </template>
      </Header>

      <div class="filters__flex-row filters__tab-menu">
        <button class="filters__tab-link"
          :class="{'filters__tab-link--active': tabs['date']}"
          @click="showTab('date')">
          {{ $t('filters.date') }}
        </button>

        <button class="filters__tab-link"
          :class="{'filters__tab-link--active': tabs['countries']}"
          @click="showTab('countries')">
          {{ $t('filters.countries') }}
          {{ countriesCount }}
        </button>

        <button class="filters__tab-link"
          :class="{'filters__tab-link--active': tabs['importance']}"
          @click="showTab('importance')">
          {{ $t('filters.importance') }}
        </button>

        <button class="filters__tab-link"
          :class="{'filters__tab-link--active': tabs['gmt']}"
          @click="showTab('gmt')">
          {{ $t('filters.gmt') }}
        </button>
      </div>

      <div class="filters__tabbed" v-if="tabs['date']">
        <FilterDates @filterWasUpdated="checkIfProxiesAreUpdated" />
      </div>

      <div class="filters__tabbed" v-if="tabs['countries']">
        <FilterCountriesSelect @filterWasUpdated="checkIfProxiesAreUpdated" />
      </div>

      <div class="filters__tabbed" v-if="tabs['importance']">
        <FilterImportance @filterWasUpdated="checkIfProxiesAreUpdated" />
      </div>

      <div class="filters__tabbed" v-if="tabs['gmt']">
        <FilterTimezones @filterWasUpdated="checkIfProxiesAreUpdated" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/mobile/Header.vue'
import FilterImportance from '@/components/mobile/FilterImportance.vue'
import FilterCountriesSelect from '@/components/mobile/FilterCountriesSelect.vue'
import FilterDates from '@/components/mobile/FilterDates.vue'
import FilterTimezones from '@/components/mobile/FilterTimezones.vue'
import utils from '@/utils'

export default {
  name: 'Filters',

  components: {
    Header,
    FilterCountriesSelect,
    FilterImportance,
    FilterDates,
    FilterTimezones
  },

  data () {
    return {
      filtersIsOpen: false,
      tabs: {
        date: true,
        countries: false,
        importance: false,
        gmt: false
      },
      filtersIsChanged: false
    }
  },

  computed: {
    filters () {
      return this.$store.state.filters
    },

    proxy () {
      return this.$store.state.proxy
    },

    locale () {
      return this.$store.state.language
    },

    headerOffset () {
      return this.$store.state.headerOffset
    },

    countriesCount () {
      const max = this.$config.regions.all.length
      const selected = this.proxy.regions.length

      if (selected === 0 || selected === max) return `(${max})`
      else return `(${selected})`
    }
  },

  methods: {
    showTab (tab2activate) {
      Object.keys(this.tabs).forEach(tabName => {
        this.tabs[tabName] = tabName === tab2activate
      })
    },

    formatDate (date) {
      return date.toLocaleDateString(this.locale, { month: 'short', day: 'numeric' })
    },

    checkIfProxiesAreUpdated () {
      const equalCountries = JSON.stringify(this.filters.regions) === JSON.stringify(this.proxy.regions) || ([0, this.$config.regions.all.length].indexOf(this.proxy.regions.length) !== -1 && [0, this.$config.regions.all.length].indexOf(this.filters.regions.length) !== -1)
      const equalImportance = JSON.stringify(this.filters.importance) === JSON.stringify(this.proxy.importance) || ([0, this.$config.importance.length].indexOf(this.proxy.importance.length) !== -1 && [0, this.$config.importance.length].indexOf(this.filters.importance.length) !== -1)
      const equalDates = this.filters.dates.from === this.proxy.dates.from && this.filters.dates.to === this.proxy.dates.to
      const equalTimezone = this.filters.timezone === this.proxy.timezone

      this.filtersIsChanged = !equalDates || !equalCountries || !equalImportance || !equalTimezone
    },

    openFilters () {
      this.$store.commit('setProxyDates', { from: this.filters.dates.from, to: this.filters.dates.to })
      this.$store.commit('setProxyRegions', { regions: this.filters.regions })
      this.$store.commit('setProxyImportance', { importance: this.filters.importance })
      this.$store.commit('setProxyTimezone', { timezone: this.filters.timezone })
      this.filtersIsOpen = true
    },

    clearFilters () {
      this.filtersIsOpen = false
    },

    applyFilters () {
      this.$store.commit('setDates', { from: this.proxy.dates.from, to: this.proxy.dates.to })
      this.$store.commit('setRegions', { regions: this.proxy.regions })
      this.$store.commit('setImportance', { importance: this.proxy.importance })
      this.$store.commit('setTimezone', { timezone: this.proxy.timezone })
      this.$store.dispatch('getEvents')
      this.filtersIsOpen = false
    }
  },

  watch: {
    filtersIsOpen (newValue) {
      return utils.blockScrolling(newValue)
    }
  }
}
</script>

<style scoped>
.filters >>> * {
  box-sizing: border-box;
}

.filters__button {
  margin: 8px 20px;
  padding: 3px 12px;
  background-color: #fff;
  border: 1px solid #CFD1D5;
  border-radius: 24px;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  outline: none;
}
.filters__button:hover {background-color: #f7f7f7;}
.filters__button:focus {background-color: #f7f7f7;}

.filters__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: #fff;
  overflow: auto;
}

.filters__header-link {
  position: relative;
  height: 40px;
  padding: 0 20px;
  border: none;
  background-color: #fff;
  color: #1192e8;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  outline: none;
}

.filters__tab-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  padding: 0 20px;
  border-bottom: 1px solid #E0E2E4;
}

.filters__tab-link {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  border-bottom: 2px solid transparent;
  color: #606264;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  outline: none;
}

.filters__tab-link--active {
  color: #606264;
  border-color: #606264;
}

.filters__tabbed {
  padding: 12px 20px;
}
</style>
