<template>
  <div class="filters__importance-list">
    <div class="filters__importance-item"
      v-for="importance in $config.importance"
      :class="{ 'importance-label--selected': isActive(importance) }"
      :key="importance">
      <label>
        <div class="filters__importance-input">
          <input type="checkbox"
            :checked="isActive(importance)"
            @change="updateImportance(importance)">
        </div>

        <div>
          <div class="filters__importance-title">
            <ItemImportance :level="importance" class="filters__importance-icon" />
            {{ $t(`importance.${importance}`) }}
          </div>
          <div class="importance-tip">{{ $t(`importance.${importance}_tip`) }}</div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import ItemImportance from '@/components/ItemImportance.vue'

export default {
  name: 'FilterImportance',

  components: {
    ItemImportance
  },

  computed: {
    selectedImportanceList () {
      return this.$store.state.proxy.importance
    }
  },

  methods: {
    updateImportance (level) {
      let importances2update

      if (this.selectedImportanceList.includes(level)) {
        importances2update = this.selectedImportanceList.filter(el => el !== level)
      } else {
        importances2update = this.selectedImportanceList.concat(level)
      }

      this.$store.commit('setProxyImportance', { importance: importances2update })
      this.$emit('filterWasUpdated')
    },

    isActive (level) {
      return this.selectedImportanceList.indexOf(level) !== -1
    }
  }
}
</script>

<style scoped>
.filters__importance-list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  background-color: #fff;
  border: 1px solid #CFD1D5;
  color: #111;
  font-size: 13px;
  line-height: 16px;
}

.filters__importance-item {
  width: 296px;
  padding: 12px;
  border-bottom: 0.5px solid #E0E2E4;
}
.filters__importance-item:first-child {border-radius: 3px 3px 0 0;}
.filters__importance-item:last-child {
  border-bottom: 0;
  border-radius: 0 0 3px 3px;
}

label {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.importance-label--selected {background: #F2F4F6;}

.filters__importance-input {
  padding-right: 12px;
}

input[type="checkbox"] {
  position: static;
  opacity: 1;
  z-index: 1; /* override the app's natives */
}

.filters__importance-title {
  margin-bottom: 4px;
  white-space: nowrap;
}

.filters__importance-icon {
  margin-right: 5px;
}

.importance-tip {
  display: block;
  color: #909294;
  font-size: 11px;
  line-height: 16px;
}
</style>
